/* eslint-disable no-unused-vars */
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button } from "@mui/material";
import { RootState, setIsCommentPanelOpen, setIsReviewEnabled } from "@platformx/authoring-state";
import {
  DefaultStateCommentIcon,
  PlatXLogo,
  SaveAnimationGif,
} from "@platformx/shared/static-assets";
import {
  CATEGORY_PAGE,
  ErrorTooltip,
  SUB_CATEGORY_PAGE,
  SubmitButton,
  Timer,
  enableReferBack,
  navigateBasedRole,
  useAccess,
} from "@platformx/utilities";
import { WorkflowHistoryIcon } from "@platformx/workflow-management";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PreviewTabsButton from "../PreviewTabsButton/PreviewTabsButton";
import { useStyles } from "./Header.styles";

const Header = ({
  lastmodifiedDate,
  handleChange,
  value,
  handleBack,
  previewStatus,
  handleSaveClick,
  handlePublishClick,
  workflow,
  timerState,
  prelemEditState,
  gifPlaying,
  createComment,
  setEnableWorkflowHistory,
  iconDisabled,
  loader,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { canAccessAction } = useAccess();
  const dispatch = useDispatch();

  const { isReviewEnabled, comments } = useSelector(
    (state: RootState) => state.comment.commentInfo,
  );
  const handleReview = () => {
    dispatch(setIsReviewEnabled(!isReviewEnabled));
    dispatch(setIsCommentPanelOpen({ value: true }));
  };
  useEffect(() => {
    if (enableReferBack(workflow) || comments?.length > 0) {
      dispatch(setIsReviewEnabled(true));
    } else {
      dispatch(setIsReviewEnabled(false));
    }
  }, [isReviewEnabled, workflow, comments]);
  const navigate = useNavigate();
  return (
    <Box className={classes.headerwp}>
      <Box className='leftHeaderBox'>
        <Box className='leftLogo'>
          <img
            src={PlatXLogo}
            alt='logo'
            className='logo'
            onClick={() => navigateBasedRole(navigate)}
          />
        </Box>
        <Box className={classes.leftwp}>
          <KeyboardBackspaceIcon onClick={handleBack} />
        </Box>
        <PreviewTabsButton
          iconDisabled={iconDisabled}
          handleChange={handleChange}
          value={value}
          previewStatus={previewStatus}
        />
      </Box>
      <Box className={classes.rightwp}>
        {comments?.length > 0 ? (
          <Box sx={{ position: "relative" }} className={classes.buttonWrapper}>
            <span color='error' className='errorcls'></span>
            <Button
              className='iconBtn'
              onClick={handleReview}
              startIcon={<img src={DefaultStateCommentIcon} width='20px' alt='' />}></Button>
          </Box>
        ) : (
          enableReferBack(workflow) && (
            <Box className={classes.buttonWrapper}>
              <Button
                className='iconBtn'
                onClick={handleReview}
                startIcon={<img src={DefaultStateCommentIcon} width='20px' alt='' />}></Button>
            </Box>
          )
        )}

        <WorkflowHistoryIcon
          enableWorkflowHistory={setEnableWorkflowHistory}
          workflow_status={workflow.workflow_status}
        />
        <Box className='Hdevider'></Box>
        {timerState && <Timer lastmodifiedDate={lastmodifiedDate} />}
        <ErrorTooltip
          component={
            <Button
              variant='secondaryButton'
              className='sm'
              data-testid='saveasdraft'
              sx={{ marginRight: "12px", marginLeft: "12px" }}
              disabled={
                !canAccessAction(CATEGORY_PAGE, SUB_CATEGORY_PAGE, "Update") || prelemEditState
              }
              onClick={() => handleSaveClick(false, false)}>
              {gifPlaying && <img src={SaveAnimationGif} alt='' />}
              {gifPlaying ? t("Saving") : t("save_as_draft")}
            </Button>
          }
          doAccess={!canAccessAction(CATEGORY_PAGE, SUB_CATEGORY_PAGE, "Update")}
        />
        {/* <ErrorTooltip
          component={
            <Button
              variant='primaryButton'
              disabled={!isPublishButtonEnabled}
              onClick={handlePublishClick}
            >
              Publish
            </Button>
          }
          doAccess={!canAccessAction(CATEGORY_PAGE, '', 'publish')}
        /> */}
        <SubmitButton
          category={CATEGORY_PAGE}
          subCategory={SUB_CATEGORY_PAGE}
          handlePublish={handlePublishClick}
          handleSave={handleSaveClick}
          workflow={workflow}
          prelemEditState={prelemEditState}
          createComment={createComment}
          publishDisabled={loader}
        />
      </Box>
    </Box>
  );
};

export default memo(Header);
