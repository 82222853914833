import { XImageRender } from "@platformx/x-image-render";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";
import { DynamicContentGallery } from "@platformx/site-page";
import { Dialog } from "@mui/material";

const ContentTypePicker = ({ field, errors, register, state, updateField }: any) => {
  const tempHide = false;
  return (
    <>
      <XImageRender
        callBack={updateField}
        editData={{
          original_image: state[field?.name]?.original_image,
          published_images: state[field?.name]?.published_images,
        }}
        isCrop={true}
        name={field?.name}
      />
      <HiddenTextBox
        error={errors[field?.name]}
        value={state[field?.name]?.original_image?.Thumbnail}
        register={register}
        name={field?.name}
        required={
          field?.validations.length > 0 &&
          field?.validations[0]?.type === "required" &&
          !state[field?.name]?.original_image?.Thumbnail
            ? field?.validations[0]?.message
            : ""
        }
      />

      {tempHide && (
        <Dialog fullScreen open={true}>
          <DynamicContentGallery
            handleSelectedContent={() => {}}
            onToggleContentGallery={() => {}}
            selectedFilters={{}}
            prelemId={""}
          />
        </Dialog>
      )}
    </>
  );
};
export default ContentTypePicker;
