import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    EditorJSMainWp: {
      position: "relative",
      "&:hover .ce-inline-toolbar": {
        display: "block !important",
      },
      "& .codex-editor": {
        "& .codex-editor__redactor": {
          paddingBottom: "0 !important",
          marginRight: "0",
          "& .ce-block": {
            margin: "12px 0",
            [theme.breakpoints.down("sm")]: {
              margin: "10px 0",
            },
            "&:first-child": {
              marginTop: 0,
            },
            "&.ce-block--selected": {
              "& .ce-block__content": {
                background: "transparent",
              },
            },
            "& .ce-block__content": {
              maxWidth: "100%",
              "& .ce-header": {
                fontWeight: theme.fontWeight.fontWeightSemiBold,
                fontSize: theme.fontSize?.fontSize_16,
                lineHeight: theme.lineHeight?.LINEHEIGHT_24,
                margin: 0,
                padding: 0,
              },
              "& blockquote": {
                borderLeft: "2px solid #b3d7ff",
                "& .cdx-quote__text": {
                  marginBottom: "14px",
                },
                "& .cdx-quote__caption": {
                  fontSize: "12px",
                },
              },
              "& .cdx-block": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
            "& a": {
              color: theme.palette.prelemType1.LINK,
            },
          },
        },
      },
      "&.withoutEditMode": {
        "& .codex-editor": {
          "& .codex-editor__redactor": {
            "& .ce-block": {
              "&.ce-block--selected": {
                "& .ce-block__content": {
                  background: "transparent",
                },
              },
            },
          },
        },
      },
      "& .ce-toolbar__content": {
        maxWidth: "100%",
      },
      "& .block:hover .ce-toolbar__content": {
        display: " block !important",
      },
      "& .ce-toolbar__actions.ce-toolbar__actions--opened": {
        left: 0,
        right: 0,
        top: "-8px",
        "& .ce-toolbar__plus, & .ce-toolbar__settings-btn": {
          display: "none",
        },
        "& .ce-popover__overlay--hidden.ce-popover__overlay": {
          borderBottom: " 2px dotted #b3d7ff",
          width: "100%",
          position: "absolute",
          display: "block",
        },
      },
      "& .ce-popover.ce-popover--opened": {
        padding: "4.7px",
        // border: "1px solid #EFF0F6",
        // boxShadow: "0px 25px 30px 0px rgba(0, 0, 0, 0.05)",
        width: "200px",
        borderRadius: theme.borderRadius.value,
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        height: "44px",
        left: 0,

        "& .ce-popover__search, & .ce-popover__nothing-found-message": {
          display: "none",
        },
        "& .ce-popover__items": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          "& .ce-popover-item": {
            borderRadius: "3px",
            "&:hover": {
              background: "#F7F7FC",
            },
            "& .ce-popover-item__title": {
              display: "none",
            },
            "& .ce-popover-item__icon": {
              marginRight: 0,
              width: "26px",
              height: "26px",
              boxShadow: "none",
              borderRadius: "3px",
              background: "transparent",
              "& svg": {
                width: "auto",
                height: "auto",
              },
            },
            "&:nth-child(1)": {
              order: 2,
            },
            "&:nth-child(2)": {
              order: 1,
            },
            "&:nth-child(3)": {
              order: 3,
            },
            "&:nth-child(4)": {
              order: 4,
            },
            "&:nth-child(5)": {
              order: 5,
            },
          },
        },
      },
      "& .ce-inline-toolbar.ce-inline-toolbar--showed": {
        padding: "5.2px",
        border: "1px solid #EFF0F6",
        boxShadow: "0px 25px 30px 0px rgba(0, 0, 0, 0.05)",
        width: "auto",
        borderRadius: theme.borderRadius.value,
        "& .ce-conversion-toolbar": {
          display: "none",
        },
        "& .ce-inline-toolbar__toggler-and-button-wrapper": {
          padding: "0",
          "& .ce-inline-toolbar__dropdown": {
            display: "none",
          },
          "& .ce-inline-toolbar__buttons": {
            borderRadius: "3px",
            "& .ce-inline-tool": {
              width: "32px",
              height: "32px",
              padding: 0,
              borderRadius: "3px",
              color: "#14142B",
              "&:hover": {
                background: "#F7F7FC",
              },
              "& svg": {
                width: "auto",
                height: "auto",
              },
            },
          },
        },
      },
      "& .codex-editor path": {
        stroke: "revert-layer",
      },
      "& .cdx-block.cdx-list": {
        paddingLeft: "20px",
        "& .cdx-list__item": {
          padding: "0",
          lineHeight: "24px",
        },
      },
      "& .ce-paragraph, & .cdx-list, & .cdx-quote__text, & .ce-header": {
        border: "0px !important",
        lineHeight: "inherit !important",
      },
      // Start Variant for Editor Js
      "&.p1regular": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightRegular,
        fontSize: theme.fontSize?.fontSize_20,
        lineHeight: theme.lineHeight?.LINEHEIGHT_28,
      },
      "&.p1medium": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightMedium,
        fontSize: theme.fontSize?.fontSize_20,
        lineHeight: theme.lineHeight?.LINEHEIGHT_28,
      },
      "&.p1semibold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightSemiBold,
        fontSize: theme.fontSize?.fontSize_20,
        lineHeight: theme.lineHeight?.LINEHEIGHT_28,
      },
      "&.p1bold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightBold,
        fontSize: theme.fontSize?.fontSize_20,
        lineHeight: theme.lineHeight?.LINEHEIGHT_28,
      },

      "&.p2regular": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightRegular,
        fontSize: theme.fontSize?.fontSize_18,
        lineHeight: theme.lineHeight?.LINEHEIGHT_26,
      },
      "&.p2medium": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightMedium,
        fontSize: theme.fontSize?.fontSize_18,
        lineHeight: theme.lineHeight?.LINEHEIGHT_26,
      },
      "&.p2semibold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightSemiBold,
        fontSize: theme.fontSize?.fontSize_18,
        lineHeight: theme.lineHeight?.LINEHEIGHT_26,
      },
      "&.p2bold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightBold,
        fontSize: theme.fontSize?.fontSize_18,
        lineHeight: theme.lineHeight?.LINEHEIGHT_26,
      },

      "&.p3regular": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightRegular,
        fontSize: theme.fontSize?.fontSize_16,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
      },
      "&.p3medium": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightMedium,
        fontSize: theme.fontSize?.fontSize_16,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
      },
      "&.p3semibold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightSemiBold,
        fontSize: theme.fontSize?.fontSize_16,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
      },
      "&.p3bold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightBold,
        fontSize: theme.fontSize?.fontSize_16,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
      },

      "&.p4regular": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightRegular,
        fontSize: theme.fontSize?.fontSize_14,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.fontSize?.fontSize_12,
          lineHeight: theme.lineHeight?.LINEHEIGHT_20,
        },
      },
      "&.p4medium": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightMedium,
        fontSize: theme.fontSize?.fontSize_14,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.fontSize?.fontSize_12,
          lineHeight: theme.lineHeight?.LINEHEIGHT_20,
        },
      },
      "&.p4semibold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightSemiBold,
        fontSize: theme.fontSize?.fontSize_14,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.fontSize?.fontSize_12,
          lineHeight: theme.lineHeight?.LINEHEIGHT_20,
        },
      },
      "&.p4bold": {
        fontFamily: theme.fontFamily.secondary,
        color: theme.palette.prelemType1.PARAGRAPH,
        fontWeight: theme.fontWeight.fontWeightBold,
        fontSize: theme.fontSize?.fontSize_14,
        lineHeight: theme.lineHeight?.LINEHEIGHT_24,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.fontSize?.fontSize_12,
          lineHeight: theme.lineHeight?.LINEHEIGHT_20,
        },
      },
      "&.textColor": { color: theme.palette.customColor.WHITECOLOR },
      "&.textColor1": { color: theme.palette.customColor.BLACKCOLOR },
      "&.textColor1Paragraph": { color: theme.palette.prelemType1.BODY_COLOR },
      "&.secondaryTitle": { color: theme.palette.prelemType2.TITLE },
      "&.secondaryParagraph": { color: theme.palette.prelemType2.PARAGRAPH },
      "&.secondaryLabel": { color: theme.palette.prelemType2.LABEL },
      "&.secondaryAnchorLink": { color: theme.palette.prelemType2.LINK },
      "&.tertiaryTitle": { color: theme.palette.prelemType3.TITLE },
      "&.tertiaryParagraph": { color: theme.palette.prelemType3.PARAGRAPH },
      "&.tertiaryLabel": { color: theme.palette.prelemType3.LABEL },
      "&.tertiaryAnchorLink": { color: theme.palette.prelemType3.LINK },
    },
  };
});
