import { fetchEcomProducts } from "../ZaggEcommerce/HelperFunction";

export const getProductDetails = (inputData: any, secondaryArgs: any) => {
  const { prelemBaseEndpoint: { deliveryEndPointGq = "" } = {}, sitename = "" } = secondaryArgs;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    pagination: { start = 0, rows = 20 } = {},
    searchTerm = "",
    tags = [],
    ecommerceRequest: { filter = [] } = {},
  }: any = inputData;

  const obj: String = `{pagination:{start:${start},rows:${rows}},searchTerm:${JSON.stringify(
    searchTerm,
  )},tags:${JSON.stringify(
    tags,
  )},filter:"Ecommerce",isSuggestive:false,ecommerceRequest:{filter:${JSON.stringify(filter)}}}`;
  return fetchEcomProducts(deliveryEndPointGq, obj, sitename);
};
