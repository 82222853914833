import { ShowToastError } from "@platformx/utilities";
import { t } from "i18next";
import { onError } from "../../utils/Helper";
import { NewQuizType, QuizType } from "./Quiz.types";

export const createInitialQuizState = (): QuizType => ({
  title: "",
  short_title: "",
  short_description: "",
  description: "",
  imagevideoURL: "",
  thumbnailURL: "",
  socialShareImgURL: "",
  titleSocialShare: "",
  descriptionSocialShare: "",
  tagsSocialShare: [],
  analytics_enable: true,
  impression: true,
  contentInsight: false,
  seo_enable: true,
  seoShared: true,
  scoreBy: t("count"),
  tags: [],
  questions: [],
  result_range_1: t("quiz_quotes_quote1"),
  result_range_2: t("quiz_quotes_quote2"),
  result_range_3: t("quiz_quotes_quote3"),
  result_range_4: t("quiz_quotes_quote4"),
  is_schedule_publish: false,
  schedule_publish_datetime: "",
  is_schedule_unpublish: false,
  schedule_unpublish_datetime: "",
  original_image: {},
  published_images: [],
  colorCode: "",
});

export const createNewQuiz = (username: string): NewQuizType => ({
  CommonFields: {
    page: "",
    title: "",
    short_title: "",
    description: "",
    short_description: "",
    category: "Quiz",
    site_name: "PlatX",
    page_state: "",
    is_edit: false,
    seo_enable: true,
    analytics_enable: true,
    robot_txt: false,
    sitemap: false,
    analytics: "",
    others: "",
    structure_data: "",
    creationDate: new Date().toISOString(),
    modificationDate: new Date().toISOString(),
    tags: [],
    createdBy: username,
    parent_page_url: "/",
    current_page_url: "",
    page_lastmodifiedby: username,
    settings: {},
  },
  ObjectFields: {
    questions: [],
    background_content: {
      objectType: "image",
      Url: "",
      Title: "",
      Thumbnail: "",
      Color: "black",
    },
    display_scores: "Count",
    result_range_1: t("quiz_quotes_quote1"),
    result_range_2: t("quiz_quotes_quote2"),
    result_range_3: t("quiz_quotes_quote3"),
    result_range_4: t("quiz_quotes_quote4"),
  },
});

export const fieldValidation = (quizState, isWorkflow, tagArr, isError, setIsError) => {
  const {
    title,
    description,
    scoreBy,
    imagevideoURL,
    colorCode,
    questions,
    is_schedule_publish,
    schedule_publish_datetime,
    is_schedule_unpublish,
    schedule_unpublish_datetime,
    titleSocialShare,
    descriptionSocialShare,
  } = quizState;
  if (isWorkflow) {
    if (title === "") {
      onError("Title");
      setIsError({ ...isError, title: true });
      ShowToastError(`${t("title")} ${t("is_required")}`);
    } else if (description === "") {
      onError("Description");
      setIsError({ ...isError, description: true });
      ShowToastError(`${t("description")} ${t("is_required")}`);
    } else if (imagevideoURL === "" && colorCode === "") {
      onError("imageVideo");
      setIsError({ ...isError, imageVideo: true });
      ShowToastError(`${t("banner_image")} ${t("is_required")}`);
    } else if (questions?.length <= 0) {
      onError("questions");
      setIsError({ ...isError, questions: true });

      ShowToastError(`${t("question")} ${t("is_required")}`);
    } else if (scoreBy === "") {
      ShowToastError(`${t("score")} ${t("is_required")}`);
    } else if (quizState.result_range_1 === "") {
      onError("results");
      setIsError({ ...isError, result_range_1: true });
      ShowToastError(`${t("range")} 0-24 ${t("is_required")}`);
    } else if (quizState.result_range_2 === "") {
      onError("results");
      setIsError({ ...isError, result_range_2: true });
      ShowToastError(`${t("range")} 24-49 ${t("is_required")}`);
    } else if (quizState.result_range_3 === "") {
      onError("results");
      setIsError({ ...isError, result_range_3: true });
      ShowToastError(`${t("range")} 50-74 ${t("is_required")}`);
    } else if (quizState.result_range_4 === "") {
      onError("results");
      setIsError({ ...isError, result_range_4: true });
      ShowToastError(`${t("range")} 75-100 ${t("is_required")}`);
    } else if (is_schedule_publish && schedule_publish_datetime === "") {
      ShowToastError(`${t("scheduled_publish")} ${t("is_required")}`);
    } else if (is_schedule_unpublish && schedule_unpublish_datetime === "") {
      ShowToastError(`${t("scheduled_unpublish")} ${t("is_required")}`);
    } else if (tagArr?.length === 0) {
      onError("tags");
      ShowToastError(t("tag_error"));
    } else if (titleSocialShare === "") {
      ShowToastError(`${t("social_share")} ${t("title")} ${t("is_required")}`);
    } else if (descriptionSocialShare === "") {
      ShowToastError(`${t("social_share")} ${t("description")} ${t("is_required")}`);
    } else {
      return true;
    }
  } else {
    if (title === "") {
      onError("Title");
      setIsError({ ...isError, title: true });
      ShowToastError(`${t("title")} ${t("is_required")}`);
    } else if (description === "") {
      onError("Description");
      setIsError({ ...isError, description: true });

      ShowToastError(`${t("description")} ${t("is_required")}`);
    } else if (
      is_schedule_publish &&
      (schedule_publish_datetime === "" || schedule_publish_datetime === null)
    ) {
      ShowToastError(`${t("scheduled_publish")} ${t("is_required")}`);
    } else if (
      is_schedule_unpublish &&
      (schedule_unpublish_datetime === "" || schedule_unpublish_datetime === null)
    ) {
      ShowToastError(`${t("scheduled_unpublish")} ${t("is_required")}`);
    } else if (questions?.length === 0) {
      onError("questions");
      setIsError({ ...isError, questions: true });
      ShowToastError(`${t("question")} ${t("is_required")}`);
    } else {
      return true;
    }
  }
};

export const updateStructureData = (content) => {
  const QuizStructureData = {
    "@context": "https://schema.org",
    "@type": "Quiz",
    name: content.title,
    description: content.description,
    hasPart:
      content.questions?.length > 0
        ? content.questions?.map(({ question, options_compound_fields }: any) => {
            return {
              "@type": "Question",
              name: question,
              suggestedAnswer:
                options_compound_fields?.length > 0
                  ? options_compound_fields?.map(({ option_text }: any) => {
                      return {
                        "@type": "Answer",
                        text: option_text,
                      };
                    })
                  : "",
            };
          })
        : "",
  };
  return QuizStructureData;
};

export const socialShareTitleSubTitles = () => {
  return {
    sectionNumber: "07",
    sectionTitle: t("social_share"),
    sectionSubTitle: t("subhead"),
    imageTitle: t("choose_image"),
    imageSubTitle: t("page_choose_image"),
    mainTitle: t("quiz_ss_title"),
    mainSubTitle: t("quiz_ss_subtitle"),
    titlePlaceHolder: t("quiz_title_placeholder"),
    descriptionTitle: t("quiz_ss_description"),
    descriptionSubTitle: t("quiz_ss_subdescription"),
    descriptionPlaceHolder: t("quiz_description_placeholder"),
    tagsTitle: t("quiz_tags_title"),
    tagsSubTitle: t("quiz_tags_subtitle"),
    titleMaxCharLength: 120,
    descriptionMaxCharLength: 400,
    titleFieldName: "titleSocialShare",
    descriptionFieldName: "descriptionSocialShare",
    imageFieldName: "socialShareImgURL",
    tagsFieldName: "tagsSocialShare",
  };
};

export const analyticsTitleSubTitles = () => {
  return {
    sectionNumber: "08",
    sectionTitle: t("analytics"),
    sectionSubTitle: t("subhead"),
    analyticsTitle: `${t("quiz")} ${t("analytics")}`,
    analyticsSubTitle: t("event_analytics_subhead"),
    impressionTitle: t("content_insight"),
    impressionSubTitle: "",
    analyticsFieldName: "analytics_enable",
    impressionFieldName: "impression",
  };
};

export const seoTitleSubTitles = () => {
  return {
    sectionNumber: "09",
    sectionTitle: t("SEO"),
    sectionSubTitle: t("subhead"),
    seoTitle: t("page_prelem_find"),
    seoSubTitle: t("seo_description"),
    shareTitle: t("page_prelem_share"),
    shareSubTitle: "",
    structureDataTitle: t("page_structure_data"),
    structureDataSubTitle: t("click_to_sd"),
    seoFieldName: "seo_enable",
    shareFieldName: "seo_shared",
    structureDataFieldName: "structure_data",
  };
};
