import { useMutation } from "@apollo/client";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { UserManagementQueries } from "@platformx/authoring-apis";
import { BlueDot, EditIcon, GreenDot, RedDot, mailboxicon } from "@platformx/shared/static-assets";
import {
  BasicSwitch,
  CommonPlateformXDialog,
  ErrorTooltip,
  Loader,
  PlatFormXDateTimeFormat,
  ShowToastError,
  ShowToastSuccess,
  XToolTip,
  getSelectedSite,
  relativeImageURL,
  useAccess,
} from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ListViewProps } from "../UserManagement.Types";
import AcceptRejectButton from "./AcceptRejectButton";
import { useCustomStyle } from "./AcceptRejectButton.styles";
import MoreDialog from "./MoreDialog";
import { ADMIN_ACTIONS, ADMIN_ACTIONS_BUTTON, USERTYPES, USER_PERMISSIONS } from "./Utils/constant";

const ListView = ({
  first_name,
  last_name,
  image,
  email,
  user_id,
  enabled,
  // timezone,
  action_pending,
  created_timestamp,
  handleReload,
  roles,
  filterValue,
  adminAction = "",
}: ListViewProps) => {
  const classes = useCustomStyle();
  const theme = useTheme();
  const [userMutate] = useMutation(UserManagementQueries.ACTIVATE_DEACTIVATE_USERS);
  const { canAccessAction } = useAccess();
  const [reSendEmailMutate] = useMutation(UserManagementQueries.RESEND_EMAIL_TO_USERS);
  const [approveRejectUser] = useMutation(UserManagementQueries.APPROVE_REJECT_USER);
  const rolename = roles?.find((obj) => obj?.site === getSelectedSite())?.name || "";
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isResend, setIsResend] = useState(false);

  // const dialog = useDialog();
  const [checked] = useState(enabled);
  const [, setIsDelete] = useState(false);
  // const role: string = localStorage.getItem('role');
  const DateTime = PlatFormXDateTimeFormat(created_timestamp);
  const isPendingWithAdmin = adminAction === ADMIN_ACTIONS.PENDING;
  const isRejectedUser = adminAction === ADMIN_ACTIONS.REJECTED;
  const userStatus =
    adminAction === ADMIN_ACTIONS.PENDING
      ? "Pending approval"
      : adminAction === ADMIN_ACTIONS.REJECTED
        ? "Rejected"
        : adminAction === ADMIN_ACTIONS.APPROVED
          ? "Approved"
          : "";
  const { category, subCategory, update } = USER_PERMISSIONS;

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const confirmDelete = () => {
    setIsDelete(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    handleDialogClose();
    try {
      await userMutate({
        variables: {
          input: {
            id: user_id,
            enabled: !enabled,
            is_Authoring_User: filterValue === USERTYPES.AUTHORINGUSER,
            is_Rendering_User: filterValue === USERTYPES.ENDUSER,
            is_Community_User: filterValue === USERTYPES.COMMUNITYUSER,
          },
        },
        onCompleted: () => {
          handleReload();
        },
      });
      setIsLoading(false);
      checked ? ShowToastSuccess(t("deactivate_message")) : ShowToastSuccess(t("activate_message"));
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;
      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error?.graphQLErrors?.[0]?.message, statusCode);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
      setIsLoading(false);
    }
  };

  const handleconfirmResend = async () => {
    setIsResend(false);
    setIsLoading(true);

    try {
      const responseEmail = await reSendEmailMutate({
        variables: {
          input: { user_id: user_id },
        },
      });
      setIsLoading(false);
      ShowToastSuccess(responseEmail.data.authoring_reinviteUser.message);
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;
      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error?.graphQLErrors?.[0]?.message, statusCode);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
      setIsLoading(false);
    }
  };

  const handleChange = () => {
    setOpenDialog(true);
  };

  const handleApproveReject = async (actionType: string) => {
    setIsLoading(true);
    try {
      const approveRejectUserResponse = await approveRejectUser({
        variables: {
          input: { users: [user_id], status: actionType },
        },
      });
      setIsLoading(false);
      ShowToastSuccess(approveRejectUserResponse?.data?.authoring_approveRejectEndUser?.message);
      handleReload();
    } catch (err: any) {
      ShowToastError(
        err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : t("api_error_toast"),
      );
      setIsLoading(false);
    }
  };

  const handleReSendMail = () => {
    setIsResend(true);
    // const dialogContent: DialogBoxContentProps = {
    //   Image: WarningIcon,
    //   Title: t("resend_invite"),
    //   Subtitle: `${t("resend_subtitle_pre")}
    //  #${email}# ${"  "}${t("")}`,
    //   LeftButtonText: t("resend_text_left_button"),
    //   RightButtonText: t("resend_text_right_button"),
    //   SubTitle2: `${t("resend_subtitle_post")}`,
    // };
    // dialog.show(dialogContent, handleconfirmResend, handleDialogClose);
  };

  const navigate = useNavigate();

  const handleEditUser = (userId: any) => {
    navigate(`/user-management/user-create?path=${userId}&usertype=${filterValue.toLowerCase()}`);
  };
  const handleCloseDialog = () => {
    setIsResend(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box className='userlistbox listbox'>
        <Grid container className='d-flex align-items-center'>
          <Grid item xs={11} em={7}>
            <Box className='d-flex align-items-center'>
              <Box className='img'>
                {image ? (
                  <img
                    src={relativeImageURL(image)}
                    style={{ width: "100%", objectFit: "cover" }}
                    alt=''
                  />
                ) : (
                  <Avatar src='/broken-image.jpg' variant='rounded' />
                )}
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant='h5medium'
                    sx={{
                      maxWidth: { xs: "80%", sm: "200px", lg: "300px" },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      wordBreak: "break-all",
                    }}>{`${first_name} ${last_name}`}</Typography>
                  <Box sx={{ marginLeft: "10px" }}>
                    {action_pending ? (
                      <img src={BlueDot} alt='Blue Icon' />
                    ) : checked ? (
                      <img src={GreenDot} alt='GreenDot Icon' />
                    ) : (
                      <img src={RedDot} alt='RedDot Icon' />
                    )}
                  </Box>
                </Box>
                {filterValue === USERTYPES.AUTHORINGUSER ? (
                  // roles.map((role) => {
                  //   return (
                  rolename && (
                    <Typography
                      //key={role._id}
                      variant='p3regular'
                      //className='doticon'
                      sx={{
                        marginLeft: { xs: 0, em: "10px" },
                        minWidth: { xs: "100%", em: "auto" },
                        paddingLeft: { xs: 0, em: "14px" },
                        display: { xs: "inline-block", em: "none" },
                      }}>
                      {rolename}
                    </Typography>
                  )
                ) : filterValue === USERTYPES.COMMUNITYUSER || filterValue === USERTYPES.ENDUSER ? (
                  <Typography
                    variant='p3regular'
                    //className='doticon'
                    sx={{
                      marginLeft: { xs: 0, em: "10px" },
                      minWidth: { xs: "100%", em: "auto" },
                      paddingLeft: { xs: 0, em: "14px" },
                      display: { xs: "inline-block", em: "none" },
                    }}>
                    {userStatus}
                  </Typography>
                ) : null}

                <Box
                  className='d-flex'
                  sx={{
                    flexWrap: { xs: "wrap", em: "inherit" },
                    alignItems: "center",
                  }}>
                  <Box display='flex'>
                    <XToolTip
                      Title={email}
                      position='top'
                      component={
                        <Box sx={{ maxWidth: { em: "220px", xl: "100%" } }}>
                          <Typography
                            variant='p3regular'
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "1",
                              WebkitBoxOrient: "vertical",
                              wordBreak: "break-all",
                              order: { xs: 2, em: 1 },
                            }}>
                            {email}
                          </Typography>
                        </Box>
                      }
                    />
                    {action_pending ? (
                      <Typography
                        variant='p3regular'
                        className='doticon'
                        sx={{
                          marginLeft: { xs: 0, em: "10px" },
                          order: { xs: 1, em: 2 },
                          minWidth: { xs: "100%", em: "auto" },
                          paddingLeft: { xs: 0, em: "14px" },
                          display: { xs: "none", em: "inline-block" },
                        }}>
                        {t("invite_pending")}
                      </Typography>
                    ) : null}
                    {filterValue === USERTYPES.AUTHORINGUSER ? (
                      rolename && (
                        <Typography
                          variant='p3regular'
                          className='doticon'
                          sx={{
                            marginLeft: { xs: 0, em: "10px" },
                            order: { xs: 1, em: 2 },
                            minWidth: { xs: "100%", em: "auto" },
                            paddingLeft: { xs: 0, em: "14px" },
                            display: { xs: "none", em: "initial" },
                          }}>
                          {rolename}
                        </Typography>
                      )
                    ) : filterValue === USERTYPES.COMMUNITYUSER ||
                      filterValue === USERTYPES.ENDUSER ? (
                      <Typography
                        variant='p3regular'
                        className='doticon'
                        sx={{
                          marginLeft: { xs: 0, em: "10px" },
                          order: { xs: 1, em: 2 },
                          minWidth: { xs: "100%", em: "auto" },
                          paddingLeft: { xs: 0, em: "14px" },
                          display: { xs: "none", em: "initial" },
                        }}>
                        {userStatus}
                      </Typography>
                    ) : null}
                  </Box>

                  <Typography variant='p3regular' className='doticonmobile'>
                    {DateTime}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} em={5}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box className={classes.dateContainer}>
                <Typography variant='p3regular' component='div'>
                  {DateTime}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "20px",
                  justifyContent: "center",
                  width: "25%",
                  alignItems: "center",
                }}>
                {(filterValue === USERTYPES.COMMUNITYUSER || filterValue === USERTYPES.ENDUSER) &&
                isPendingWithAdmin ? (
                  <>
                    <AcceptRejectButton
                      onClick={() => handleApproveReject(ADMIN_ACTIONS_BUTTON.APPROVED)}
                      variant={ADMIN_ACTIONS_BUTTON.SUCCESS}
                    />
                    <AcceptRejectButton
                      onClick={() => handleApproveReject(ADMIN_ACTIONS_BUTTON.REJECTED)}
                      variant={ADMIN_ACTIONS_BUTTON.ERROR}
                    />
                  </>
                ) : (
                  <>
                    <ErrorTooltip
                      component={
                        <IconButton
                          data-testid='edit'
                          className='hoverIcon'
                          disabled={!canAccessAction(category, subCategory, update)}
                          onClick={() => !isRejectedUser && handleEditUser(user_id)}>
                          <img src={EditIcon} alt='Edit Icon' />
                        </IconButton>
                      }
                      doAccess={!canAccessAction(category, subCategory, update)}
                    />

                    {action_pending ? (
                      <IconButton
                        data-testid='sendmail'
                        className='hoverIcon'
                        onClick={() => handleReSendMail()}
                        disabled={isRejectedUser}>
                        <img src={mailboxicon} alt='mailBox' />
                      </IconButton>
                    ) : (
                      <Box sx={{ marginLeft: "10px", display: "flex" }}>
                        <BasicSwitch
                          checked={checked}
                          color={theme.palette.customColor.PRIMARY}
                          bgcolor={theme.palette.customColor.PLACEHOLDER}
                          onChange={handleChange}
                          disabled={isRejectedUser}
                          basicSwitchRootClassName='switchRootClass'
                        />
                      </Box>
                    )}
                  </>
                )}
                {(((filterValue === USERTYPES.COMMUNITYUSER || filterValue === USERTYPES.ENDUSER) &&
                  !isPendingWithAdmin) ||
                  filterValue === USERTYPES.AUTHORINGUSER) && (
                  <Box sx={{ display: { xs: "flex", em: "none" } }}>
                    <MoreDialog
                      user_id={user_id}
                      first_name={first_name}
                      last_name={last_name}
                      checked={checked}
                      onChange={handleChange}
                      handleDelete={confirmDelete}
                      handleEditUser={handleEditUser}
                      action_pending={action_pending}
                      handleReSendMail={handleReSendMail}
                      disabled={isRejectedUser}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <PlateformXDialog
          isDialogOpen={isResend}
          title={t("resend_invite")}
          subTitle={`${t("resend_subtitle_pre")}
        ${email}`}
          closeButtonText={t("resend_invite")}
          confirmButtonText={t("back")}
          closeButtonHandle={handleconfirmResend}
          confirmButtonHandle={() => setIsResend(false)}
          crossButtonHandle={() => {
            setIsResend(false);
          }}
          modalType='unsavedChanges'
        /> */}
        {isResend ? (
          <CommonPlateformXDialog
            isDialogOpen={isResend}
            title={t("resend_invite")}
            subTitle={`${t("resend_subtitle_pre")}
            ${email}`}
            closeButtonText={t("back")}
            closeButtonHandle={handleCloseDialog}
            confirmButtonText={t("resend_invite")}
            confirmButtonHandle={handleconfirmResend}
            modalType='unsavedChanges'
          />
        ) : null}
        {openDialog && (
          <CommonPlateformXDialog
            isDialogOpen={openDialog}
            title={checked ? t("deactivate_title") : t("activate_title")}
            subTitle={
              checked ? `${t("deactivate_subtitle_pre")}?` : `${t("activate_subtitle_pre")}?`
            }
            closeButtonText={t("no")}
            confirmButtonText={t("yes")}
            closeButtonHandle={handleDialogClose}
            confirmButtonHandle={handleConfirm}
          />
        )}
      </Box>
    </>
  );
};

export default ListView;
