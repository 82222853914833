/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { replace, upload } from "@platformx/shared/static-assets";
import { capitalizeFirstLetter, uriToJSONforButtonEditWindow } from "@platformx/utilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { ECOM_PRELEM_LIST } from "../utils/constants";
import "./ButtonEditWindow.css";
import ButtonEditWindowStyle from "./ButtonEditWindow.style";
import { iconReplaceBasedCondition } from "./helperButtonEditWindow";
import InternalPageList from "./InternalPageList";

interface ButtonEditWindowProps {
  prelemData?: any;
  onOpenContentType?: any;
  zIndex: number;
  opacity: string;
  left: string;
  top: string;
  buttonRef: any;
  dropDownMenuRef: React.Ref<HTMLDivElement>;
  handleWindowOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrlChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrlInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectPageUrlChange: (event: object) => void;
  windowValue: string;
  pageUrl: string;
  externalLink: string;
  urlValue: string;
  contentUrl: string;
  onToggleContentGallery: () => void;
  onOutsideClick?: any;
  eComContentGalleryHandle?: any;
  jsValue?: any;
  handleJsValueChange?: (event: SelectChangeEvent<any>) => void;
}
export const ButtonEditWindow = ({
  prelemData,
  onOpenContentType,
  zIndex,
  opacity,
  left,
  top,
  buttonRef,
  handleWindowOptionChange,
  handleSelectPageUrlChange,
  handleUrlInputChange,
  handleUrlChange,
  windowValue,
  pageUrl,
  externalLink,
  dropDownMenuRef,
  urlValue,
  contentUrl,
  onToggleContentGallery,
  onOutsideClick,
  eComContentGalleryHandle,
  jsValue,
  handleJsValueChange,
}: ButtonEditWindowProps) => {
  const { PrelemId = "" } = prelemData;
  const isEcom = ECOM_PRELEM_LIST.includes(PrelemId);

  const { t } = useTranslation();
  const [ecomTypeData, setEcomTypeData] = useState<any>({});
  const [ContentTypeData, setContentTypeData] = useState<any>({});
  const { ContentType = "", isGalleryArray = "", Title = "" } = ContentTypeData;
  const { ecommerceRequest: { filter = [] } = {} } = ecomTypeData;
  const ecomFilterData = filter?.length > 0 ? true : false;
  const commonRadioButton = () => {
    return (
      <RadioGroup
        name='window-radio-buttons-group'
        value={windowValue}
        onChange={handleWindowOptionChange}
        row>
        <FormControlLabel
          value='current window'
          control={<Radio />}
          label='Current Tab'
          sx={{
            marginRight: "30px",
          }}
        />
        <FormControlLabel
          value='new window'
          control={<Radio />}
          label='New Tab'
          sx={{
            marginRight: "30px",
          }}
        />
      </RadioGroup>
    );
  };

  useEffect(() => {
    if (contentUrl && typeof contentUrl === "string") {
      if (contentUrl.includes("ContentEnCodeParse")) {
        //for convert parse condition check manual
        setContentTypeData(uriToJSONforButtonEditWindow(contentUrl));
      } else {
        setContentTypeData({});
      }
    } else {
      setContentTypeData({});
    }

    if (contentUrl && typeof contentUrl === "string") {
      if (contentUrl.includes("ecomEnCodeParse")) {
        setEcomTypeData(uriToJSONforButtonEditWindow(contentUrl));
      } else {
        setEcomTypeData({});
      }
    } else {
      setEcomTypeData({});
    }
  }, [contentUrl]);

  const style = `
  .contentInputHandle .Platform-x-OutlinedInput-notchedOutline{
    border-color: transparent !important;
  }
  `;
  const classes = ButtonEditWindowStyle();
  return (
    <Box className={classes.buttonEditWindowWp}>
      <OutsideClickHandler onOutsideClick={(e) => onOutsideClick(e)}>
        <Box
          className='boxWarp'
          sx={{
            width: { xs: "92%", sm: "550px" },
            marginTop: { xs: "20px", sm: "0" },
            opacity: opacity,
            zIndex: zIndex,
          }}
          data-testid='empty-state-wrap'
          ref={buttonRef}>
          <style>{style}</style>
          <RadioGroup
            name='page-radio-buttons-group'
            value={urlValue}
            onChange={handleUrlChange}
            className='popup-radio-options'
            row
            sx={{ marginBottom: urlValue === "Content" ? "15px" : "25px" }}>
            <FormControlLabel
              className='form-label'
              value='Internal'
              control={<Radio />}
              label='Page'
            />
            <FormControlLabel
              className='form-label'
              value='External'
              control={<Radio />}
              label='Link'
            />
            {!isEcom && (
              <FormControlLabel
                className='form-label'
                value='Content'
                control={<Radio />}
                label='Content'
              />
            )}

            {isEcom && (
              <FormControlLabel
                className='form-label'
                value='Ecommerce'
                control={<Radio />}
                label='Ecommerce'
              />
            )}
          </RadioGroup>
          {urlValue === "Internal" && (
            <InternalPageList
              pageUrl={pageUrl}
              dropDownMenuRef={dropDownMenuRef}
              handleSelectPageUrlChange={handleSelectPageUrlChange}
            />
          )}
          {urlValue === "External" && (
            <TextField
              id='outlined-name'
              placeholder='Paste URL here'
              variant='filled'
              value={externalLink}
              onChange={handleUrlInputChange}
              sx={{
                width: "100%",
                marginBottom: "25px",
              }}
            />
          )}

          {/*Content  */}
          {urlValue === "Content" ? (
            <>
              <Box
                sx={{
                  marginBottom: "15px",
                }}>
                <Box>
                  <Typography
                    variant='p3regular'
                    sx={{
                      paddingBottom: "5px",
                    }}>
                    {t("add_your_content")}
                  </Typography>
                </Box>

                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Grid item className='contentWarper' md={10} lg={10} xs={12} sm={12}>
                    {ContentType && iconReplaceBasedCondition(ContentTypeData)}
                    <Box
                      className='inputWp'
                      sx={{
                        paddingLeft: ContentType ? "35px" : "5px",
                      }}>
                      {ContentType ? (
                        <Box
                          sx={{
                            justifyContent: "start",
                            display: "flex",
                            flexDirection: "row",
                          }}>
                          <Typography variant='p3regular'>
                            {`"${capitalizeFirstLetter(Title)}" `}
                          </Typography>
                          <Typography>{"Added!"}</Typography>
                        </Box>
                      ) : (
                        <Box component='span'>{t("content_type_added")}</Box>
                      )}
                      {/* {ContentType ? `"${ContentType}" Added!` : t("content_type_added")} */}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    lg={2}
                    xs={12}
                    sm={12}
                    sx={{
                      paddingLeft: { md: "10px" },
                      mt: {
                        xs: 1,
                        md: 0,
                      },
                    }}>
                    <Box
                      onClick={onOpenContentType}
                      className='rightblackbox'
                      sx={{
                        width: { xs: "100% important", md: "0px" },
                        minWidth: { xs: "100% important", md: "40px" },
                      }}>
                      <img alt='uploadAndReplace' src={ContentType ? replace : upload} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {!isGalleryArray ? <>{commonRadioButton()}</> : null}
            </>
          ) : urlValue === "Ecommerce" && isEcom ? (
            <>
              <Box
                sx={{
                  marginBottom: "15px",
                }}>
                <Box>
                  <Typography
                    variant='p3regular'
                    sx={{
                      paddingBottom: "5px",
                    }}>
                    {t("ecom_add_product")}
                  </Typography>
                </Box>

                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Grid
                    item
                    md={10}
                    lg={10}
                    xs={12}
                    sm={12}
                    className='contentInputHandle contentWarper'>
                    <Box
                      className='inputWp'
                      sx={{
                        paddingLeft: ContentType ? "35px" : "5px",
                      }}>
                      {ecomFilterData ? (
                        <Box
                          sx={{
                            justifyContent: "start",
                            display: "flex",
                            flexDirection: "row",
                          }}>
                          <Typography variant='p3regular'>{t("ecom_product_added")}</Typography>
                        </Box>
                      ) : (
                        <Box component='span'>{t("ecom_no_product")}</Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    lg={2}
                    xs={12}
                    sm={12}
                    sx={{
                      paddingLeft: { md: "10px" },
                      mt: {
                        xs: 1,
                        md: 0,
                      },
                    }}>
                    <Box
                      onClick={() => eComContentGalleryHandle(contentUrl)}
                      className='rightblackbox'
                      sx={{
                        width: { xs: "100% important", md: "0px" },
                        minWidth: { xs: "100% important", md: "40px" },
                      }}>
                      <img alt='uploadAndReplace' src={ContentType ? replace : upload} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {commonRadioButton()}
            </>
          ) : (
            <>{commonRadioButton()}</>
          )}
        </Box>
      </OutsideClickHandler>
    </Box>
  );
};
