import { Box, Button, Container, Typography } from "@mui/material";
import { FormItem } from "@platformx/form-builder-components";
import { successGif } from "@platformx/shared/static-assets";
import { Loader } from "@platformx/utilities";
import axios from "axios";
import { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Confirmation from "../../components/Confirmation/Confirmation";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import ToastService from "../../components/ToastContainer/ToastService";
import prelemTypes from "../../globalStyle";
import { GET_QUERY, SAVE_QUERY } from "./FormConstant";
import { useCustomStyle } from "./FormCreator.style";
import FormSkelton from "./FormSkelton";

const FormCreator = ({ content, authoringHelper, secondaryArgs }) => {
  const classes = useCustomStyle();
  const [formData, setFormData] = useState([]);
  const [selectedElement, setSelectedElement] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const globalClasses = prelemTypes();
  const onContentReplace = () => {
    secondaryArgs?.multiSlot?.onToggleContentGallery("FORM", true);
  };
  const form = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  const { register, formState, clearErrors, handleSubmit } = form;
  const { errors } = formState;
  const handleSave = (savedFormData) => {
    setPageLoader(true);
    const data = JSON.stringify({
      query: SAVE_QUERY,
      variables: {
        input: {
          contentType: "formbuilder",
          form_name: EditorialItemPath,
          form_data: { ...savedFormData },
        },
      },
    });

    const config = {
      method: "post",
      url: secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
      headers: {
        language: "en",
        site_host: secondaryArgs?.sitename,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        const apiResonspe = response?.data;
        if (apiResonspe?.errors?.length > 0) {
          ToastService.failToast(apiResonspe?.errors?.[0]?.message);
        } else {
          setSuccessPopUp(true);
        }
      })
      .catch(function (error) {
        ToastService.failToast(error);
      })
      .finally(() => {
        setPageLoader(false);
      });
  };
  const EditorialItemPath =
    content?.Slots?.[0]?.EditorialItemPath === "NULL"
      ? null
      : content?.Slots?.[0]?.EditorialItemPath?.replace(/^\/|\/$/g, "");

  const transformFormData = (formDataArr) => {
    return formDataArr?.map((item) => item.element_schema);
  };
  const handleClose = () => {
    setSuccessPopUp(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios({
          method: "post",
          url: secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
          headers: {
            site_host: secondaryArgs?.sitename,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            query: GET_QUERY(EditorialItemPath),
            variables: {},
          }),
          maxBodyLength: Infinity,
        });
        const transformedData = transformFormData(
          response?.data?.data?.publish_contentDetail?.form_data,
        );
        transformedData && setFormData(transformedData);
      } catch (error) {
        /* empty */
      } finally {
        setLoading(false);
      }
    };
    if (EditorialItemPath) fetchData();
  }, [EditorialItemPath]);

  return (
    // <ThemeProvider theme={AuthroingTheme}>
    <Box
      ref={authoringHelper?.innerRef}
      className={`${classes.formCreatorWrapper} ${globalClasses.prelemType1} prelem prelemType1 formCreator ${authoringHelper?.isEditPage && "noscroll"}`}>
      <Container
        className={
          authoringHelper?.isEditPage
            ? "grid_full_width prelem-py"
            : "grid_container grid_container_nopadding prelem-py"
        }
        sx={{ height: "100%" }}>
        <Box
          className='formCreatorPrelem'
          sx={{
            "&:hover": {
              ".add-content-overlay": {
                display: authoringHelper?.isEditing ? "flex !important" : "none",
              },
            },
          }}>
          {loading ? <FormSkelton /> : null}
          {!loading && formData?.length === 0 ? (
            <>
              <Typography variant='h2medium' id='title' className='title'>
                {content?.title}
              </Typography>
              <Typography variant='h2medium' id='description' className='title'>
                {content?.description}
              </Typography>
            </>
          ) : null}
          {!loading && formData?.length > 0 ? (
            <Box className='formSection editForm'>
              <form onSubmit={handleSubmit(handleSave)}>
                {formData?.length > 0 &&
                  formData?.map((element, index) => (
                    <Box key={index} className={"form-element selected"}>
                      <Suspense fallback='loading...'>
                        <FormItem
                          item={element}
                          register={register}
                          clearErrors={clearErrors}
                          errors={errors}
                          setSelectedElement={setSelectedElement}
                          selectedElement={selectedElement}
                        />
                      </Suspense>
                    </Box>
                  ))}
                <Box className='submitButton form-element' mt={2}>
                  <Button variant='primaryButton1' className='sm' type='submit'>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          ) : null}
          <ContentReplace onReplaceClick={onContentReplace} />
        </Box>
        {successPopUp && (
          <Confirmation
            open={true}
            img={successGif}
            handleClose={handleClose}
            text={"Form Data Saved Successfully"}
            title={"Success"}
          />
        )}
        {pageLoader && <Loader></Loader>}
      </Container>
    </Box>
    // </ThemeProvider>
  );
};

FormCreator.defaultProps = {
  content: {
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
    title: "Forms",
    PrelemContentType: ["formbuilder"],
    Slots: [
      {
        Title: "New Gameplay Features Unveiled in FIFA Red's Latest Update",
        Banner:
          "https://stg.dam.hcl-x.com/server/api/core/bitstreams/34981c50-a864-49d0-8cb3-72905d768f63/content",
        Thumbnail: {
          Name: "new-gameplay-features-unveiled-in-fifa-red-s-latest-update",
          Url: "1694490277593/public/jpeg/1",
          Title: "New Gameplay Features Unveiled in FIFA Red's Latest Update",
          Description:
            "The excitement continues to build in the world of FIFA Red as the latest update unveils a series of groundbreaking gameplay features that are set to redefine the soccer gaming experience. Whether you're a casual player or a die-hard fan, these innovations are bound to leave you in awe.1. Hyper-Realistic Player MovementOne of the most anticipated enhancements in this update is the hyper-realistic player movement. FIFA Red now brings players to life like never before, with fluid animations and lifelike reactions.&nbsp;\u003Cdiv\u003E\u003Cbr\u003E\u003C/div\u003E\u003Cdiv\u003EEvery step, dribble, and pass is executed with astonishing authenticity, blurring the lines between virtual and real-world soccer.2. Dynamic Weather EffectsPrepare to face the elements with FIFA Red's dynamic weather effects. Rain-soaked pitches, swirling snow, and intense sunlight will challenge your skills and add a new layer of strategy to every match. Adapt to changing conditions and master the elements to secure victory.3. Enhanced Tactical DepthFIFA Red's latest update also introduces enhanced tactical depth.&nbsp;\u003C/div\u003E\u003Cdiv\u003E\u003Cbr\u003E\u003C/div\u003E\u003Cdiv\u003EExperience more control over your team's playstyle, with advanced tactics that allow you to dictate the tempo, press high, or defend resolutely. It's a game-changer for those who crave strategic depth in their soccer gaming experience.4. Smoother Skill MovesSkill moves have received an overhaul, making them smoother and more intuitive than ever. Dazzle your opponents with jaw-dropping tricks and flicks, and master the art of dribbling with precision. The revamped skill moves system promises to elevate your gameplay to new heights.\u003C/div\u003E",
          Attribution: false,
          AltText: "New Gameplay Features Unveiled in FIFA Red's Latest Update",
          ext: "jpg",
          visibility: "public",
        },
        Description:
          "The excitement continues to build in the world of FIFA Red as the latest update unveils a series of groundbreaking gameplay features that are set to redefine the soccer gaming experience. Whether you're a casual player or a die-hard fan, these innovations are bound to leave you in awe.1. Hyper-Realistic Player MovementOne of the most anticipated enhancements in this update is the hyper-realistic player movement. FIFA Red now brings players to life like never before, with fluid animations and lifelike reactions.&nbsp;\u003Cdiv\u003E\u003Cbr\u003E\u003C/div\u003E\u003Cdiv\u003EEvery step, dribble, and pass is executed with astonishing authenticity, blurring the lines between virtual and real-world soccer.2. Dynamic Weather EffectsPrepare to face the elements with FIFA Red's dynamic weather effects. Rain-soaked pitches, swirling snow, and intense sunlight will challenge your skills and add a new layer of strategy to every match. Adapt to changing conditions and master the elements to secure victory.3. Enhanced Tactical DepthFIFA Red's latest update also introduces enhanced tactical depth.&nbsp;\u003C/div\u003E\u003Cdiv\u003E\u003Cbr\u003E\u003C/div\u003E\u003Cdiv\u003EExperience more control over your team's playstyle, with advanced tactics that allow you to dictate the tempo, press high, or defend resolutely. It's a game-changer for those who crave strategic depth in their soccer gaming experience.4. Smoother Skill MovesSkill moves have received an overhaul, making them smoother and more intuitive than ever. Dazzle your opponents with jaw-dropping tricks and flicks, and master the art of dribbling with precision. The revamped skill moves system promises to elevate your gameplay to new heights.\u003C/div\u003E",
        PublishedDate: "2023-09-23T14:29:53Z",
        lastModifiedDate: "2023-09-23T14:29:44Z",
        ContentType: "Article",
        tags: '["Articles","Blogs"]',
        Author: "James Mallan",
        CurrentPageURL: "/new-gameplay-features-unveiled-in-fifa-red-s-latest-update",
        hclplatformx_EditorialTags: "new-gameplay-features-unveiled-in-fifa-red-s-latest-update",
        EditorialItemPath: "new-gameplay-features-unveiled-in-fifa-red-s-latest-update",
        PublishedBy: "James",
        Id: "new-gameplay-features-unveiled-in-fifa-red-s-latest-update",
      },
    ],
  },
  authoringHelper: {
    innerRef: null,
    sendStructureDataToAuthoringCB: () => {},
    sendDefaultStructureDataForResetToAuthoringCB: () => {},
    openButtonEditWindowInAuthoringCB: () => {},
    selectedButtonNameForEditing: "",
    isEditing: false,
    buttonRef: null,
    buttonContentEditable: false,
    lastSavedStructuredData: "",
    isEditPage: false,
  },
  analytics: {
    isAnalyticsEnabled: true,
    isSeoEnabled: false,
    isAuthoring: false,
    position: 0,
    pageId: 12345,
    prelemId: 23456,
    pageTitle: "FAQ 1",
    pageDesc:
      "This prelem can be used to display the FAQs. If the number of FAQs is more than 4 FAQs, the FAQs move via scroll",
    pageTags: "FAQs, FAQ, Questions, Query, Question",
    prelemTags: "FAQs, FAQ, Questions, Query, Question",
  },
  secondaryArgs: {
    prelemBaseEndpoint: {
      APIEndPoint: "https://platx-api-dev.fanuep.com/platform-x/",
      device: "window",
      buttonBaseUrl: "https://platx-publish-dev.fanuep.com/",
    },
    editState: false,
    multiSlot: {},
  },
};

export default FormCreator;
