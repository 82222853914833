/* eslint-disable react/jsx-key */
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  backgroundColor: theme.palette.customColor.LINE,
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.customColor.PRIMARY,
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${theme.palette.customColor.BGLIGHT},${theme.palette.customColor.BGLIGHT} 44%,transparent 49%)`,
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.customColor.PRIMARY,
  },
}));

const RadioGroupButtons = ({
  value,
  disabled = false,
  activeColor = "default",
  state,
  handleChange,
  field,
  row,
  error,
  register,
}: any) => {
  return (
    <Box>
      <RadioGroup
        name={field?.name}
        value={state[field?.name] || field?.default_value}
        onChange={handleChange}
        row={row}>
        {value.map((val) => {
          return (
            <FormControlLabel
              sx={{
                ".Platform-x-FormControlLabel-label": {
                  fontSize: "14px",
                },
                marginRight: "30px",
              }}
              value={val.value}
              control={
                <Radio
                  disableRipple
                  color={activeColor}
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
              }
              // label={label ? label : value}
              label={val.value}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
      <HiddenTextBox
        error={error}
        value={state[field?.name] || ""}
        register={register}
        name={field?.name}
        required={!state[field?.name]?.length ? field?.validations[0]?.message : ""}
      />
    </Box>
  );
};
export default RadioGroupButtons;
