import { getRestApiCall, postRestApiCall } from "@platformx/utilities";

/**
 * courseId based get course fill details
 * post call
 */
export const getCourseDetailsApiCall = (courseId: string, secondaryArgs: any) => {
  const {
    prelemBaseEndpoint: {
      deliveryEndPoint = "https://marvericks.delivery.hcl-x.com/platform-x/",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;
  return getRestApiCall(
    `${deliveryEndPoint}api/v1/web/en/delivery/course-model?path=${courseId}`,
    language,
    sitename,
  );
  // return getRestApiCall(
  //   `https://marvericks.delivery.hcl-x.com/platform-x/api/v1/web/en/delivery/course-model?path=108058619401306`
  // );
};

/**
 * courseId based get course fill details
 * post call
 */
export const getLearningListApiCall = (ele: any) => {
  const { secondaryArgs = {}, userId = "" } = ele;
  const {
    prelemBaseEndpoint: {
      // deliveryEndPoint = "https://dev.users.hcl-x.com/platform-x/user-service/",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;

  const data = JSON.stringify({
    query: `query { userCoursesList(user_id:${JSON.stringify(userId)}) {
      user_id 
      title 
      description 
      author 
      lessons 
      teaser 
      teaser_image 
      course_id 
      course_url 
      status
      created_at
      updated_at
    } } `,
  });
  return postRestApiCall(
    `https://dev.users.hcl-x.com/platform-x/user-service/`,
    data,
    language,
    sitename,
  );
};
export const getDeviceType = () => {
  const viewportWidth = window?.innerWidth;
  if (viewportWidth < 768) {
    return "Mobile";
  } else if (viewportWidth < 1024) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};
const getDeviceOS = () => {
  const ua = navigator?.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/i.test(ua) && !globalThis.MSStream) {
    return "iOS";
  } else if (/Macintosh|Mac OS X/i.test(ua)) {
    return "macOS";
  } else if (/Windows NT/i.test(ua)) {
    return "Windows";
  } else if (/Linux/i.test(ua)) {
    return "Linux";
  } else {
    return "Other";
  }
};
export const getRpiInstance = () => {
  if (typeof window !== "undefined" && globalThis?.rpiWebClient) {
    return true;
  }
  return false;
};
export const getUserId = () => {
  return localStorage.getItem("userId") || "anonymous";
};
export const getLocationStorage = () => {
  return localStorage.getItem("locationData");
};
export const getLang = () => {
  return "en";
};

export const ecomProductImpressionEvent = (pageData) => {
  if (getRpiInstance()) {
    // Retrieve user and visitor IDs from localStorage
    const storedUserId = getUserId();
    // Create the event data object
    const eventData = [
      { Name: "action", Value: pageData?.action || "a_product_view" },
      { Name: "content_type", Value: pageData?.content_type || "ecom" },
      { Name: "container_type", Value: pageData?.container_type || "container_value" }, // Add relevant value
      { Name: "tags", Value: pageData?.tags || "iphone11 | Case | mobilecase" },
      { Name: "product_Id", Value: pageData?.product_Id },
      { Name: "product_name", Value: pageData?.product_name },
      { Name: "product_price", Value: pageData?.product_price },
      { Name: "product_category", Value: pageData?.product_category },
      { Name: "page_name", Value: pageData?.page_name },
      { Name: "page_url", Value: pageData?.page_url },
      { Name: "language", Value: pageData?.language || "en" }, // Dynamic site language
      { Name: "timestamp", Value: pageData?.timestamp || new Date().toISOString() },
      { Name: "site_name", Value: pageData?.site_name || "YourSiteName" },
      { Name: "userId", Value: storedUserId }, // Use Keycloak ID or anonymous
      { Name: "device_type", Value: getDeviceType() },
      { Name: "device_browser", Value: globalThis?.rpiWebClient?.detectBrowser() || "chrome" },
      { Name: "device_os", Value: getDeviceOS() },
      { Name: "country", Value: pageData?.country || "India" },
      { Name: "city", Value: pageData?.city || "Delhi" },
      { Name: "ip", Value: pageData?.ip || "127.0.0.1" },
    ];

    // Send the event data via rpiWebClient
    globalThis.rpiWebClient.pushWebEvent(
      "w_ecom_product_impression", // Event name
      1, // Quantity or other relevant parameter
      "ecom Impression", // Event description
      null, // Optional content ID or other identifier
      eventData, // Pass the event data object
    );
  }
};
export const ecomAddToCartImpressionEvent = (pageData) => {
  if (getRpiInstance()) {
    const eventData = [
      { Name: "action", Value: pageData?.action },
      { Name: "cart_id", Value: pageData?.cart_id },
      { Name: "content_type", Value: pageData?.content_type },
      { Name: "container_type", Value: pageData?.container_type },
      { Name: "tags", Value: pageData?.tags },
      { Name: "product_id", Value: pageData?.product_id },
      { Name: "product_name", Value: pageData?.product_name },
      { Name: "quantity", Value: pageData?.quantity },
      { Name: "price_per_unit", Value: pageData?.price_per_unit },
      { Name: "currency", Value: pageData?.currency },
      { Name: "total_price", Value: pageData?.total_price },
      { Name: "product_category", Value: pageData?.product_category },
      { Name: "page_name", Value: pageData?.page_name },
      { Name: "page_url", Value: pageData?.page_url },
      { Name: "language", Value: pageData?.language },
      { Name: "timestamp", Value: pageData?.timestamp },
      { Name: "site_name", Value: pageData?.site_name },
      { Name: "userld", Value: getUserId() },
      { Name: "device_type", Value: getDeviceType() },
      { Name: "device_browser", Value: globalThis?.rpiWebClient?.detectBrowser() },
      { Name: "device_os", Value: getDeviceOS() },
      { Name: "country", Value: pageData?.country },
      { Name: "city", Value: pageData?.city },
      { Name: "ip", Value: pageData?.ip },
    ];
    globalThis.rpiWebClient.pushWebEvent(
      "w_add_to_cart", // Event name
      1, // Quantity or other relevant parameter
      "Ecom add to cart Impression", // Event description
      null, // Optional content ID or other identifier
      eventData, // Pass the event data object
    );
  }
};
