import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { FETCH_TAG_LIST_QUERY } from "@platformx/authoring-apis";
import { debounce, ShowToastError } from "@platformx/utilities";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";
import TagListing from "./TagListing";
import { tagInlineCss } from "./TagListingStyles";

interface Tag {
  name: string;
  isCheck: boolean;
}

interface Category {
  category: string;
  tags: string[];
  newTag: Tag[];
}

interface TagsProps {
  field: any;
  subCategory?: string;
  isEdit?: boolean;
  tagData?: Category[];
  selectedTag?: any;
  isPublishModal?: boolean;
  updateState: (a, b) => void;
  register: any;
  error: any;
}

export const TagListingComponent = (props: TagsProps) => {
  const { t } = useTranslation();
  const {
    isEdit = false,
    selectedTag,
    subCategory = "",
    isPublishModal,
    updateState,
    field,
    register,
    error,
  } = props;
  const [runFetchTagList] = useLazyQuery(FETCH_TAG_LIST_QUERY);
  const [tagArray, setTagArray] = useState<Category[]>([]);
  const [tagData, setTagData] = useState<Category[]>([]);
  const oneTimeLoad = useRef(false);
  const [tagArr, setTagArr] = useState<any>([]);
  // Sorts an array of strings alphabetically
  const nameSort = (users: string[]): string[] => {
    if (users?.length) {
      return [...users].sort((a, b) => a.localeCompare(b));
    }
    return [];
  };
  const handleTagOnChange = (event) => {
    let tagsArray = [...tagArr];
    if (event.target.checked && tagsArray?.length > 14) {
      event.target.checked = false;
      ShowToastError(t("allowed_tags_toast"));
    } else {
      if (event.target.checked) {
        if (!tagArr.includes(event.target.value)) tagsArray = [...tagArr, event.target.value];
      } else {
        tagsArray.splice(tagArr.indexOf(event.target.value), 1);
      }
      setTagArr(tagsArray);
      updateState(field?.name, tagsArray);
    }
  };
  // Updates the checked status of a tag and handles validation
  const updateTagFieldCheck = (e: React.ChangeEvent<HTMLInputElement>, tagsData: Category) => {
    const { target: { value = "", checked = false } = {} } = e;
    const { newTag: newTagsArray = [] } = tagsData;
    let countArray: Tag[] = [];
    const oldArray: Category[] = [...tagArray];
    oldArray.forEach((ele: Category) => {
      const getValue = ele.newTag.filter((eleFilter: Tag) => eleFilter.isCheck === true);
      countArray = [...countArray, ...getValue];
    });

    // Limit the number of selected tags to 15
    if (countArray?.length > 14 && checked) {
      ShowToastError(t("allowed_tags_toast"));
    } else {
      handleTagOnChange?.(e);
      const findIndexMainArray = oldArray.findIndex(
        (ele: Category) => ele.category === tagsData.category,
      );
      const findIndexNestedArray = newTagsArray.findIndex((ele: Tag) => ele.name === value);

      const findObj = oldArray[findIndexMainArray]?.newTag[findIndexNestedArray];
      if (findObj) {
        const newObj = {
          ...findObj,
          isCheck: !findObj.isCheck,
        };
        oldArray[findIndexMainArray].newTag[findIndexNestedArray] = newObj;

        const checkedArray = oldArray[findIndexMainArray].newTag
          .filter((ele) => ele.isCheck === true)
          .map((ele: Tag) => ele.name);
        const unCheckedArray = oldArray[findIndexMainArray].newTag
          .filter((ele) => ele.isCheck === false)
          .map((ele: Tag) => ele.name);
        const concatArray = [...nameSort(checkedArray), ...nameSort(unCheckedArray)];
        oldArray[findIndexMainArray].tags = concatArray;

        // Trigger state update with a new array
        setTagArray([...oldArray]);
      }
    }
  };

  // Prepares the tag array for rendering
  const tagArrayPreFetch = (tagsArray: Category[]) => {
    const newArr = tagsArray?.map((eleTag: Category) => {
      const filterIsChecked = eleTag?.newTag
        ?.filter((filter: Tag) => filter.isCheck === true)
        .map((ele: Tag) => ele.name);
      const filterIsNotChecked = eleTag.newTag
        ?.filter((filter: Tag) => filter.isCheck === false)
        .map((ele: Tag) => ele.name);

      return {
        ...eleTag,
        tags: [...filterIsChecked, ...nameSort(filterIsNotChecked)],
      };
    });
    setTagArray(newArr);
  };

  // Handles the creation of tags specific to a content type
  const contentTypeCreateTagHandle = (arr: Category[]) => {
    if (subCategory && arr.length > 0) {
      const newArray = [...arr];
      const newArrIndex = newArray.findIndex((ele: Category) => ele?.category === "Content Type");

      if (newArrIndex >= 0) {
        const indexFind = newArray?.[newArrIndex]?.newTag?.findIndex(
          (ele: Tag) => ele?.name === subCategory,
        );
        if (indexFind >= 0) {
          newArray[newArrIndex].newTag[indexFind] = {
            ...newArray[newArrIndex]?.newTag[indexFind],
            isCheck: true,
          };
        }
      }
      tagArrayPreFetch(newArray);
    } else {
      setTagArray(arr);
    }
  };

  // Debounced function to handle suggestions
  const handleSuggestions = debounce((value: Category[]) => tagArrayPreFetch(value), 500);

  // Effect to handle initial data load and updates
  useEffect(() => {
    if (tagData?.length) {
      if (!oneTimeLoad.current) {
        const newArr = tagData?.map((eleTag: Category) => {
          return {
            ...eleTag,
            tags: eleTag.tags?.length ? nameSort(eleTag.tags) : [],
            newTag: eleTag.tags?.map((ele) => {
              return {
                name: ele,
                isCheck: !isEdit ? ele === subCategory : selectedTag.includes(ele),
              };
            }),
          };
        });

        if (!isEdit) {
          contentTypeCreateTagHandle(newArr);
        } else {
          handleSuggestions(newArr);
        }
        oneTimeLoad.current = true;
      }
    } else {
      setTagArray([]);
    }
  }, [tagData, isEdit, selectedTag, subCategory]);

  useEffect(() => {
    if (tagArray.length === 0) {
      runFetchTagList({
        variables: { start: 0, rows: 1000 },
      })
        .then((res) => {
          if (res?.data?.authoring_getTagsList) {
            setTagData(res?.data?.authoring_getTagsList);
          }
        })
        .catch(() => {
          ShowToastError(t("api_error_toast"));
        });
    }
  }, []);

  return (
    <Box
      className='noSpace'
      sx={{
        backgroundColor: "#ffffff",
        marginBottom: "12px",
      }}>
      <style>{tagInlineCss}</style>
      <Box className='vod-cat-tag'>
        {tagArray.length > 0 &&
          tagArray.map((categories, index) => (
            <TagListing
              key={index}
              categories={categories}
              updateTagField={updateTagFieldCheck}
              selectedTags={selectedTag}
              isPublishModal={isPublishModal}
            />
          ))}
      </Box>
      <HiddenTextBox
        error={error}
        value={selectedTag || ""}
        register={register}
        name={field?.name}
        required={
          field?.validations.length > 0 &&
          field?.validations?.[0]?.type === "required" &&
          selectedTag === ""
            ? field?.validations[0]?.message
            : ""
        }
      />
    </Box>
  );
};

export default React.memo(TagListingComponent);
