import { TextField, useTheme } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import { dateAndTimeValidator } from "../../../utils/helperFns";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

interface DateAndTimePickerProps {
  isDisabled?: boolean;
  disablePast?: boolean;
  state?: any;
  setState?: any;
  field?: any;
  isValid?: any;
  register: any;
  clearErrors: any;
  error: boolean;
  updateState: any;
}

export default function DateAndTimePicker({
  field,
  error,
  register,
  state,
  updateState,
  clearErrors,
  disablePast,
}: DateAndTimePickerProps) {
  const theme = useTheme();
  const [date, setDate] = React.useState<Date | null>(null);
  const [errorState, setErrorState] = React.useState({
    state: false,
    message: field?.validations[0]?.message,
  });

  const updateDate = (selectedDate) => {
    if (error) {
      clearErrors(field.name);
    }
    setDate(selectedDate);
    updateState(field.name, new Date(selectedDate)?.toISOString());
    const isValid =
      field?.validations[1]?.type === "before"
        ? dateAndTimeValidator(state[field.dependent_name], new Date(selectedDate)?.toISOString())
        : dateAndTimeValidator(new Date(selectedDate)?.toISOString(), state[field.dependent_name]);
    if (isValid) {
      setErrorState({ state: true, message: field?.validations[1]?.message });
    } else {
      setErrorState({ state: false, message: field?.validations[0]?.message });
    }
  };

  // const updateDate = (selectedDate) => {
  //   if (error) {
  //     clearErrors(field.name);
  //   }
  //   setDate(selectedDate);
  //   updateState(field.name, new Date(selectedDate)?.toISOString());
  //   const isBefore = field?.validations.find((x) => x.type === "before");
  //   const isAfter = field?.validations.find((x) => x.type === "after");
  //   const isValid = isBefore
  //     ? dateAndTimeValidator(state[isBefore?.dependent_name], new Date(selectedDate)?.toISOString())
  //     : isAfter
  //       ? dateAndTimeValidator(
  //           new Date(selectedDate)?.toISOString(),
  //           state[isAfter?.dependent_name],
  //         )
  //       : false;
  //   if (isValid) {
  //     setErrorState({
  //       state: true,
  //       message: isBefore ? isBefore?.message : isAfter ? isAfter?.message : "",
  //     });
  //   } else {
  //     setErrorState({ state: false, message: "" });
  //   }
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        inputFormat='MM-DD-YYYY hh:mm a'
        disablePast={disablePast}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
            error={error}
            {...register(field.name, {
              required: !date && errorState?.message,
              validate: () => !errorState?.state || errorState?.message,
            })}
            sx={{
              width: "100%",
              border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
              borderRadius: "5px",
              backgroundColor: error
                ? `${theme.palette.customColor.BACKGROUND_ERROR}`
                : "transparent",
            }}
          />
        )}
        value={state[field.name] === "" ? date : state[field.name]}
        onChange={(newValue) => updateDate(newValue)}
      />
      <ErrorMessageHandle error={error} />
    </LocalizationProvider>
  );
}
