import { Box, TextField, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

interface DatePickerProps {
  isDisabled?: boolean;
  disablePast?: boolean;
  state?: any;
  setState?: any;
  field?: any;
  isValid?: any;
  register: any;
  clearErrors: any;
  error: boolean;
}

const DatePickerComponent = ({
  state,
  setState,
  field,
  register,
  clearErrors,
  error,
  disablePast,
}: DatePickerProps) => {
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    if (error) {
      clearErrors(field.name);
    }
    setSelectedDate(date);
    setState({
      ...state,
      [field.name]: new Date(date).toISOString(),
    });
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disablePast={disablePast}
          renderInput={(params) => (
            <TextField
              variant='filled'
              name={field.name}
              // helperText={!selectedDate && helperText}
              error={error}
              {...register(field.name, {
                required: !selectedDate && field?.validations[0]?.message,
              })}
              {...params}
              onKeyDown={handleDateChangeRaw}
              autoComplete='off'
              sx={{
                width: "100%",
                border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                borderRadius: "5px",
                backgroundColor: error
                  ? `${theme.palette.customColor.BACKGROUND_ERROR}`
                  : "transparent",
              }}
            />
          )}
          inputFormat='YYYY-MM-DD'
          value={state[field.name] === "" ? selectedDate : state[field.name]}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
      <ErrorMessageHandle error={error} />
    </Box>
  );
};
export default DatePickerComponent;
