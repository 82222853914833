import { Box, Divider } from "@mui/material";
import { CATEGORY_CONTENT, SectionWrapper } from "@platformx/utilities";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SectionProps } from "../../utils/types";
import { CreateHeader } from "../common/CreateHeader/CreateHeader";
import DynamicSectionComponent from "../DynamicSectionComponent";
import { useCustomStyle } from "./DynamicForm.style";

export const DynamicComponent = ({
  contentType,
  template,
  handleSave,
  contentInstance,
  setContentInstance,
  isEdit,
  setPageState,
}) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();
  const navigate = useNavigate();

  //Grouping fields based on index values
  const groupedFields = template?.fields?.reduce((result, field) => {
    const { index, ...rest } = field;
    const existingGroup = result?.find((group) => group.index === index);
    if (existingGroup) {
      existingGroup.fields.push(rest);
    } else {
      result.push({
        index,
        title: template?.form_groups?.find((x) => x.index === index)?.title,
        description: template?.form_groups?.find((x) => x.index === index)?.description,
        fields: [rest],
      });
    }
    return result;
  }, []);

  //ReactHookForm setup
  const form = useForm({
    defaultValues: contentInstance,
    mode: "onBlur",
  });

  const { register, formState, clearErrors, handleSubmit } = form;
  const { errors } = formState;

  const onError = (errorss, e) => {
    //function to focus errors on custom elements like image picker, tags
    e.preventDefault();
    if (Object.keys(errorss).length > 0) {
      const goToError = document.getElementById(`${Object.keys(errorss)[0]}_id`);
      if (goToError) goToError.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave, onError)}>
      <Box mb={3}>
        <CreateHeader
          createText={template?.title}
          publishText={t("publish")}
          saveText={t("save_as_draft")}
          previewText={t("preview")}
          toolTipText={t("preview_tooltip")}
          saveVariant='outlined'
          category={CATEGORY_CONTENT}
          subCategory={"quiz"}
          id={""}
          hasPreviewButton={false}
          handleReturn={() => {
            navigate(`/content/${contentType}`);
          }}
          handlePublish={() => {
            setPageState("publish");
          }}
          handleSaveOrPublish={() => {
            setPageState("save");
          }}
          editText={""}
          isQuiz={false}
          hasPublishButton={false}
          hasSaveButton={false}
          showPreview={false}
          workflow={{ enable: false }}
          hasTimerState={false}
          lastModifiedDate={""}
          isFeatured={false}
        />
        <Divider></Divider>
      </Box>

      {groupedFields?.length > 0 &&
        groupedFields.map((section: SectionProps, index: any) => {
          return (
            <Box className={classes.mainStyleWrapper} key={index}>
              <SectionWrapper
                number={section.index}
                title={section.title}
                subTitle={section.description}
                titleVariant={"h5bold"}
                subTitleVariant={"p4regular"}>
                <DynamicSectionComponent
                  fields={section.fields}
                  state={contentInstance}
                  setState={setContentInstance}
                  errors={errors}
                  register={register}
                  clearErrors={clearErrors}
                  isEdit={isEdit}
                  contentType={contentType}
                />
              </SectionWrapper>
            </Box>
          );
        })}
    </form>
  );
};
