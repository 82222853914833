import "quill-paste-smart";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "./EditorBubbleTheme.css";

type CommonDraftProps = {
  optionEditor?: any;
  passingToHtml?: any;
  description?: string;
  editState?: boolean;
};

const Link = Quill.import("formats/link");

class MyLink extends Link {
  static create(value) {
    const node = super.create(value);
    let values = this.sanitize(value);
    if (!values.startsWith("https")) {
      values = "https://" + values;
    }
    node.setAttribute("href", values);
    return node;
  }
}

const Font = ReactQuill.Quill.import("formats/font"); // <<<< ReactQuill exports it
Font.whitelist = ["Inter"]; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

Quill.register(MyLink);

const Block = Quill.import("blots/block");
class MyBlock extends Block {
  static create() {
    const node = super.create();
    // Next, customize other functions
    node.setAttribute("style", "margin: 0 0 10px 0;");
    return node;
  }
}
Quill.register(MyBlock);

const CommonDraftDescription = (props: CommonDraftProps) => {
  const {
    description = "",
    editState = false,
    passingToHtml = () => {},
    optionEditor = [
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ indent: "-1" }, { indent: "+1" }],
      ["bold", "italic", "underline", "link"],
      ["blockquote"],
    ],
  } = props;
  const styleHandle = `.ql-editor {
        padding-left: 0 !important;
    }.ql-container.ql-snow {
        border: none !important;
        font-size: 18px;
    }.rdw-editor-toolbar {
        display: ${!editState ? "none !important" : "block"};
    }.border-none .public-DraftEditor-content{
        border: 2px dashed transparent;
    }`;

  const [value, setValue] = useState<string>("");
  const reactQuillRef = useRef<ReactQuill>(null);

  const editorText = (e: string) => {
    setValue(e);
    passingToHtml(e);
  };

  const isHTML = (str: any) => {
    const elem = document.createElement("p");
    elem.innerHTML = str;
    return elem.children.length > 0;
  };

  useEffect(() => {
    if (description) {
      if (isHTML(description)) {
        setValue(description);
        passingToHtml(description);
      } else {
        setValue(`<p>${description}</p>`);
        passingToHtml(`<p>${description}</p>`);
      }
    }
  }, [description]);

  return (
    <>
      <style>{styleHandle}</style>
      <ReactQuill
        modules={{
          toolbar: optionEditor,
        }}
        ref={reactQuillRef}
        theme='bubble'
        value={value}
        preserveWhitespace
        onChange={(v) => {
          // replace tabs with spaces
          editorText(v?.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"));
        }}
        // onChange={editorText}
      />
    </>
  );
};
export default React.memo(CommonDraftDescription);
