import { gql } from "@apollo/client";

export const PUBLISH_CONTENT_TYPE = gql`
  mutation authoring_publishContent(
    $input: authoring_PublishInfo
    $contentType: authoring_ContentTypes!
  ) {
    authoring_publishContent(contentType: $contentType, input: $input) {
      parent_page_url
      message
      current_page_url
      __typename
    }
  }
`;

export const PUBLISH_CONTENT = gql`
  mutation authoring_publishSchemaContent($contentType: String, $input: authoring_PublishData) {
    authoring_publishSchemaContent(contentType: $contentType, input: $input) {
      message
      path
      __typename
    }
  }
`;

export const CREATE_CONTENT_TYPE = gql`
  mutation authoring_createContent(
    $input: authoring_ContentInputRequest
    $contenttype: authoring_ContentTypes!
  ) {
    authoring_createContent(contentType: $contenttype, input: $input) {
      message
      isExist
      path
      __typename
    }
  }
`;

export const SAVE_CONTENT_TYPE = gql`
  mutation authoring_saveSchemaContent($contentType: String, $input: authoring_JSON) {
    authoring_saveSchemaContent(contentType: $contentType, input: $input) {
      id
      name
      message
      path
      __typename
    }
  }
`;

export const UPDATE_CONTENT_TYPE = gql`
  mutation authoring_updateContent(
    $input: authoring_ContentInputRequest
    $contenttype: authoring_ContentTypes!
  ) {
    authoring_updateContent(contentType: $contenttype, input: $input) {
      message
      path
      __typename
    }
  }
`;

export const UPDATE_CONTENT = gql`
  mutation authoring_updateSchemaContent($contentType: String, $input: authoring_JSON) {
    authoring_updateSchemaContent(contentType: $contentType, input: $input) {
      message
    }
  }
`;

export const DELETE_CONTENT_TYPE = gql`
  mutation authoring_deleteContent($contentType: String!, $page: String!) {
    authoring_deleteContent(contentType: $contentType, page: $page) {
      path
      message
      __typename
    }
  }
`;
