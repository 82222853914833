import { Box, Grid } from "@mui/material";
import { CommonBoxWithNumber, DatePicker, TextBox, TitleSubTitle } from "@platformx/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "../../CreateEvent.styles";
import { ComponentProp } from "../../CreateEvent.types";

const EventTimeAndLocation = ({
  state,
  setState,
  unsavedChanges,
  isError,
  setIsError,
}: ComponentProp) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    unsavedChanges.current = true;
  };

  const startHandleValChange = (event) => {
    setState({
      ...state,
      event_start_date: event?.toISOString(),
    });
    unsavedChanges.current = true;
  };

  const endHandleDateChange = (event) => {
    setState({
      ...state,
      event_end_date: event?.toISOString(),
    });
    unsavedChanges.current = true;
    setIsError({ ...isError, event_end_date: false });
  };

  const handleDateChangeRaw = (e: React.ChangeEvent<HTMLElement>) => {
    e.preventDefault();
  };
  const rightEmptySpace = () => {
    return <Grid item xs={12} sm={5} md={5} lg={5} className='grid' id='code'></Grid>;
  };
  const handleChange = (e) => {
    unsavedChanges.current = true;
    setIsError({ ...isError, [e.target.name]: false });
  };
  return (
    <Box id='eventTimeAndLocation' className={classes.mainStyleWrapper}>
      <CommonBoxWithNumber
        number='03'
        title={t("event_timing_head")}
        titleVarient='h5bold'
        subTitleVarient='p4regular'
        subTitle={t("subhead")}>
        <Grid container>
          {/* Start Date & Time */}
          <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
            <TitleSubTitle
              title={t("event_start_time")}
              subTitle={t("event_start_subtime")}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled'>
            <Box mr={2}>
              <DatePicker
                time={state?.event_start_date ? state?.event_start_date : ""}
                handleValChange={startHandleValChange}
                handleDateChangeRaw={handleDateChangeRaw}
                isDisabled={false}
                disablePast={false}
              />
            </Box>
          </Grid>

          {/* End Date & Time */}
          <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
            <TitleSubTitle
              title={t("event_end_time")}
              subTitle={t("event_end_subtime")}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled' id='eventEndDate'>
            <Box mr={2}>
              <DatePicker
                time={state?.event_end_date ? state?.event_end_date : ""}
                handleValChange={endHandleDateChange}
                handleDateChangeRaw={handleDateChangeRaw}
                isDisabled={false}
                disablePast={false}
                error={isError["event_end_date"]}
              />
            </Box>
          </Grid>

          {/* Web Link*/}
          <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
            <TitleSubTitle
              title={t("event_link_")}
              subTitle={t("event_sublink")}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled' id='link'>
            <TextBox
              name='webLink'
              state={state.webLink}
              handleOnBlur={handleOnBlur}
              handleChange={handleChange}
              placeHolder={t("event_link_placeholder")}
              maxCharLength={500}
              error={isError["webLink"]}
            />
          </Grid>

          {/* Address */}
          <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
            <TitleSubTitle
              title={`${t("event_address")}*`}
              subTitle={t("event_subaddress")}
              titleVariant='h6medium'
              subTitleVariant='p4regular'
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled' id='eventAddress'>
            <TextBox
              name='address'
              state={state.address}
              handleOnBlur={handleOnBlur}
              handleChange={handleChange}
              placeHolder={t("event_address_placeholder")}
              maxCharLength={200}
              error={isError["address"]}
            />
          </Grid>

          {/* address local */}

          {rightEmptySpace()}

          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            className='textFiled'
            sx={{
              paddingRight: "13px",
            }}>
            <TextBox
              name='locality'
              state={state.locality}
              handleOnBlur={handleOnBlur}
              placeHolder={t("locality")}
              maxCharLength={200}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} className='textFiled'>
            <TextBox
              name='postalCode'
              state={state.postalCode}
              handleOnBlur={handleOnBlur}
              handleChange={handleChange}
              placeHolder={t("postal_code")}
              maxCharLength={10}
              error={isError["postalCode"]}
            />
          </Grid>

          {/* address state */}
          {rightEmptySpace()}

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className='textFiled'
            sx={{
              paddingRight: "13px",
            }}>
            <TextBox
              name='regionState'
              state={state.regionState}
              handleOnBlur={handleOnBlur}
              placeHolder={t("region_state")}
              maxCharLength={200}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} className='textFiled'>
            <TextBox
              name='country'
              state={state.country}
              handleOnBlur={handleOnBlur}
              placeHolder={t("country")}
              maxCharLength={200}
            />
          </Grid>
        </Grid>
      </CommonBoxWithNumber>
    </Box>
  );
};

export default React.memo(EventTimeAndLocation);
