import { Box, Typography } from "@mui/material";
import { NoSearchResultsvg } from "@platformx/shared/static-assets";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NoAssetUpload = ({ type = "images" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Function to handle redirect
  const redirectToImagesPage = () => {
    navigate(`/asset/${type}`);
  };

  // Time to wait before redirecting (in milliseconds)
  const redirectionDelay = 500; // 500 ms

  // Perform auto-redirect after a delay
  setTimeout(redirectToImagesPage, redirectionDelay);

  return (
    <Box textAlign='center'>
      <img alt='' src={NoSearchResultsvg} width={175} height={175} />
      <Typography variant='h3bold'>{t("no_assets_to_upload")}</Typography>
    </Box>
  );
};

export default NoAssetUpload;
