import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";
import "./AutoCompleteDropDown.css";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' sx={{ color: "#14142B" }} />;
const checkedIcon = <CheckBoxIcon fontSize='small' sx={{ color: "#14142B" }} />;
export type AutoCompleteMultiSelectProps = {
  options: any;
  state?: any;
  optionFormat?: string;
  setState?: any;
  field?: any;
  clearErrors?: any;
  error: any;
  register: any;
};
export const AutoCompleteDropDown = ({
  options,
  state = [],
  optionFormat = "",
  setState,
  field,
  clearErrors,
  error,
  register,
}: AutoCompleteMultiSelectProps) => {
  const { placeholder = "", multiple = true, dependent_name = "" } = field || {};
  const handleChange = (_: any, newValue) => {
    clearErrors(field?.name);
    setState({
      ...state,
      [field.name]: multiple ? newValue : [newValue],
    });
  };
  const defaultValues =
    state[field.name] && (multiple ? [...state[field.name]] : state[field.name][0]);
  const theme = useTheme();
  return (
    <Box id={`${field?.name}_id`}>
      <FormControl fullWidth error={error}>
        <Autocomplete
          value={defaultValues}
          popupIcon={<ExpandMoreIcon />}
          className='inviteMembersAutoComplete'
          multiple={multiple}
          id='checkboxesdemos'
          isOptionEqualToValue={(option: any, value) =>
            option[optionFormat] === value[optionFormat]
          }
          options={options}
          disabled={dependent_name && !state[dependent_name]}
          disableCloseOnSelect
          onChange={handleChange}
          classes={{
            popper: "autocompleteMultiSelectPopper",
            paper: "autocompleteMultiSelectPapper",
            inputRoot: "autocompleteMultiSelectInputRoot",
            tag: "autocompleteMultiSelectTag",
          }}
          renderOption={(props, option: any, { selected }) => (
            <li {...props}>
              {multiple ? (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              ) : null}
              {option[optionFormat]}
            </li>
          )}
          renderInput={(params) => {
            return (
              <TextField
                variant='filled'
                style={{
                  border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                  background: error ? "#FFEBEE" : "transparent",
                  borderRadius: "5px",
                }}
                {...params}
                placeholder={placeholder}
              />
            );
          }}
        />
      </FormControl>
      <HiddenTextBox
        error={error}
        value={state[field?.name]?.length || ""}
        register={register}
        name={field?.name}
        required={!state[field?.name]?.length ? field?.validations[0]?.message : ""}
      />
    </Box>
  );
};
