import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { handleDialog } from "@platformx/authoring-state";
import { iconsList } from "@platformx/content";
import {
  DraftStatusIcon,
  SchedulePublishStatusIcon,
  WarningIcon,
} from "@platformx/shared/static-assets";
import { PlatFormXDateTimeFormat, ShowToastError } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { TaskPage } from "../taskPages/TaskPages.type";
import { useStyles } from "./TaskRow.styles";

const Tasks = ({
  created_by,
  creation_date,
  description,
  document_path,
  document_title,
  document_type,
  last_modified_by,
  task_status,
  title,
  changeStatus,
  edit,
  objData,
}: TaskPage) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleconfirmReject = () => {
    changeStatus({
      last_modified_by,
      title,
      status: "Rejected",
    });
  };

  const handleconfirmAccept = () => {
    if (document_type.toLowerCase() === "assetmanager") {
      navigate(`/asset/create/${title}`);
    } else {
      changeStatus({
        last_modified_by,
        title,
        status: "Accepted",
      });
    }
  };

  const handleReject = () => {
    const dialogContent = {
      imageIcon: WarningIcon,
      isOpen: true,
      title: t("Reject_Task"),
      subTitle: t("reject_subtitle"),
      rightButtonText: t("Yes"),
      leftButtonText: t("No"),
      handleCallback: handleconfirmReject,
    };
    dispatch(handleDialog(dialogContent));
  };

  const handleEdit = () => {
    if (document_type.toLowerCase() === "assetmanager") return;
    task_status.toLowerCase() === "accepted"
      ? edit(
          {
            ContentType: document_type,
            page: document_path.split("/").pop(),
          },
          objData,
        )
      : ShowToastError("Accept the task before redirect");
  };

  return (
    <Box className={classes.Tasklistbox}>
      <Grid
        container
        sx={{
          display: "flex",
          padding: "0px 15px",
          alignItems: { xs: "flex-start", em: "center" },
        }}>
        <Grid xs={12} em={8} lg={6}>
          <Box
            sx={{
              display: { xs: "flex", em: "none" },
              padding: " 19px 0px",
              minWidth: { xs: "auto", em: "170px" },
              minHeight: { xs: "20px", em: "40px" },
            }}>
            <Box>
              {document_type.toLowerCase() === "assetmanager" ? (
                <Tooltip title={t("draft")} placement='top-end'>
                  <img src={DraftStatusIcon} alt={t("draft")} />
                </Tooltip>
              ) : (
                <Tooltip title={t("ready_for_review")} placement='top-end'>
                  <img src={SchedulePublishStatusIcon} alt={t("ready_for_review")} />
                </Tooltip>
              )}
              {/* <Chip
                icon={
                  document_type.toLowerCase() === "assetmanager" ? (
                    <img src={DraftStatusIcon} alt={t("draft")} />
                  ) : (
                    <img src={ScheduleUnpublishStatusIcon} alt={t("ready_for_review")} />
                  )
                }
                // variant={
                //   document_type.toLowerCase() === "assetmanager" ? "draftBadge" : "pendingBadge"
                // }
              /> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "0px 10px 0 0",

              justifyContent: "space-between",
              paddingBottom: { md: "20px", em: "0px" },
            }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className={classes.BoxImage}
                sx={{
                  marginRight: { xs: "10px", md: "20px" },
                  display: { xs: "flex" },
                }}>
                <img
                  src={iconsList[document_type?.toLowerCase()]}
                  style={{ width: "100%", objectFit: "cover" }}
                  alt=''
                />
              </Box>
              <Box
                sx={{
                  maxWidth: { md: "575px", em: "376px", lg: "630px" },
                  display: "flex",
                  justifyItems: "center",
                  flexDirection: "column",
                }}>
                <Typography
                  className={
                    document_type.toLowerCase() === "assetmanager"
                      ? classes.Description
                      : classes.Title
                  }
                  variant='h5medium'
                  sx={{
                    marginBottom: "2px",
                  }}
                  onClick={handleEdit}
                  data-testid='dashboard-task-edit'>
                  {document_title}
                </Typography>
                <Box
                  sx={{
                    display: { xs: "none", lg: "inline-block" },
                    flexWrap: { xs: "wrap", em: "inherit" },
                    alignItems: { xs: "flex-start", em: "center" },
                    flexDirection: { xs: "row", em: "row" },
                  }}>
                  {document_type.toLowerCase() !== "assetmanager" && (
                    <Typography
                      className={classes.Description}
                      variant='p3regular'
                      sx={{
                        order: { xs: 2, em: 1 },
                      }}>
                      {description}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      em: "flex",
                      lg: "none",
                    },
                    padding: {
                      xs: "0 10px 0 0",
                      md: "0 15px 0 0",
                    },
                    minWidth: { xs: "auto", em: "170px" },
                    minHeight: { xs: "20px", em: "40px" },
                    alignItems: "center",
                  }}>
                  <Typography variant='p4regular'>{created_by}</Typography>
                  <Box className={classes.Blackdot}></Box>
                  <Typography variant='p4regular' sx={{ paddingLeft: "10px" }}>
                    {PlatFormXDateTimeFormat(creation_date)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex", em: "none" },
                justifyContent: "flex-end",
                color: "#89909A",
              }}>
              {task_status === "Accepted" ? (
                <Box
                  sx={{
                    justifyContent: "center",
                    display: { xs: "none", md: "flex", em: "none" },
                    width: " 135px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}>
                  <Button
                    variant='primaryButton'
                    className='sm'
                    onClick={handleEdit}
                    data-testid='dashboard-task-accept'>
                    {t("review")}
                  </Button>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex", em: "none" },
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}>
                    <Button
                      variant='primaryButton'
                      className='sm'
                      onClick={() => handleconfirmAccept()}
                      data-testid='dashboard-task-confirm-accept'>
                      {document_type.toLowerCase() === "assetmanager" ? t("edit") : t("accept")}
                    </Button>
                  </Box>
                  {document_type.toLowerCase() === "assetmanager" ? (
                    <Box
                      className={classes.CrossIcon}
                      sx={{
                        display: {
                          xs: "none",
                          md: "flex",
                          em: "none",
                        },
                      }}>
                      <Button variant='tertiaryButton' className='sm' disabled>
                        <CloseIcon />
                      </Button>
                      {/* <CloseIcon style={{ color: "#D9DBE9" }} fontSize='small' /> */}
                    </Box>
                  ) : (
                    <Box
                      onClick={() => handleReject()}
                      data-testid='dashboard-task-reject'
                      className={classes.CrossIcon}
                      sx={{
                        display: { xs: "none", md: "flex", em: "none" },
                      }}>
                      <Button variant='tertiaryButton' className='sm'>
                        <CloseIcon />
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} em={4} lg={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "flex-end", em: "flex-end" },
            }}>
            <Box
              className='d-flex align-items-center'
              sx={{
                display: { xs: "none", em: "flex" },
                justifyContent: "center",
                padding: {
                  xs: "0 10px",
                  em: "0 15px",
                },
                minWidth: { xs: "auto", em: "95px" },
                borderLeft: {
                  xs: `1px solid ${theme.palette.customColor.LINE}`,
                  em: `1px solid ${theme.palette.customColor.LINE}`,
                },
                borderRight: {
                  xs: `1px solid ${theme.palette.customColor.LINE}`,
                  em: `1px solid ${theme.palette.customColor.LINE}`,
                },
                minHeight: { xs: "20px", em: "40px" },
              }}>
              <Box>
                {document_type.toLowerCase() === "assetmanager" ? (
                  <Tooltip title={t("draft")} placement='top-end'>
                    <img src={DraftStatusIcon} alt={t("draft")} />
                  </Tooltip>
                ) : (
                  <Tooltip title={t("ready_for_review")} placement='top-end'>
                    <img src={SchedulePublishStatusIcon} alt={t("ready_for_review")} />
                  </Tooltip>
                )}
                {/* <Chip
                  icon={
                    document_type.toLowerCase() === "assetmanager" ? (
                      <img src={DraftStatusIcon} alt={t("draft")} />
                    ) : (
                      <img src={ScheduleUnpublishStatusIcon} alt={t("ready_for_review")} />
                    )
                  }
                  // label={
                  //   document_type.toLowerCase() === "assetmanager"
                  //     ? t("draft")
                  //     : t("ready_for_review")
                  // }
                  // variant={
                  //   document_type.toLowerCase() === "assetmanager" ? "draftBadge" : "pendingBadge"
                  // }
                /> */}
              </Box>
            </Box>
            <Box
              className='webdatetime'
              sx={{
                display: {
                  xs: "none",
                  lg: "flex",
                },
                flexDirection: "column",
                padding: {
                  xs: "0 10px 0 0",
                  md: "0 15px 0 0",
                  em: "0 15px",
                },
                minWidth: { xs: "auto", em: "190px" },
                maxWidth: { xs: "auto", em: "190px" },
                marginRight: { xs: "15px" },
                minHeight: { xs: "20px", em: "40px" },
                borderRight: `1px solid ${theme.palette.customColor.LINE}`,
              }}>
              <Box>
                <Typography variant='p4regular' component='div'>
                  {created_by}
                </Typography>
              </Box>
              <Box>
                <Typography variant='p4regular' component='div'>
                  {PlatFormXDateTimeFormat(creation_date)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                minWidth: "140px",
                paddingLeft: { em: "9px" },
              }}>
              {task_status === "Accepted" ? (
                <Box
                  sx={{
                    justifyContent: "center",
                    display: { xs: "none", em: "flex" },
                    cursor: "pointer",
                  }}>
                  <Button
                    variant='primaryButton'
                    className='sm'
                    onClick={handleEdit}
                    data-testid='dashboard-task-accept-1'>
                    {t("review")}
                  </Button>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: { xs: "none", em: "flex" },
                      alignItems: "center",
                    }}>
                    <Button
                      variant='primaryButton'
                      className='sm'
                      onClick={() => handleconfirmAccept()}
                      data-testid='dashboard-task-confirm-1'>
                      {document_type.toLowerCase() === "assetmanager" ? t("edit") : t("accept")}
                    </Button>
                  </Box>
                  {document_type.toLowerCase() === "assetmanager" ? (
                    <Box
                      className={classes.CrossIcon} // Apply styles for disabled state
                      sx={{
                        display: {
                          xs: "none",
                          em: "flex",
                        },
                      }}>
                      <Button variant='tertiaryButton' className='sm' disabled>
                        <CloseIcon />
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      onClick={() => handleReject()}
                      data-testid='dashboard-task-reject-1'
                      className={classes.CrossIcon}
                      sx={{
                        display: {
                          xs: "none",
                          em: "flex",
                        },
                      }}>
                      <Button variant='tertiaryButton' className='sm'>
                        <CloseIcon />
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box
            className={classes.Button}
            sx={{
              display: { xs: "flex", md: "none" },
              paddingRight: { md: "250px" },
            }}>
            {task_status === "Accepted" ? (
              <Button
                variant='primaryButton'
                className='sm'
                onClick={handleEdit}
                data-testid='dashboard-task-review'>
                {t("review")}
              </Button>
            ) : (
              <>
                <Button
                  variant='primaryButton'
                  className='sm'
                  onClick={() => handleconfirmAccept()}
                  data-testid='dashboard-task-confirm-accept'>
                  {document_type.toLowerCase() === "assetmanager" ? t("edit") : t("accept")}
                </Button>
                <Button
                  disabled={document_type.toLowerCase() === "assetmanager"}
                  variant='secondaryButton'
                  className='sm'>
                  {t("reject")}
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tasks;
