import { Container, Grid } from "@mui/material";
import { TitleSubTitle } from "@platformx/utilities";
import { AutoCompleteDropDown } from "./common/AutoCompleteDropDown/AutoCompleteDropDown";
import CheckBoxComponent from "./common/CheckBox/CheckBoxComponent";
import ContentTypePicker from "./common/ContentTypePicker/ContentTypePicker";
import DateAndTimePicker from "./common/DateAndTimePicker/DateAndTimePicker";
import DatePickerComponent from "./common/DatePicker/DatePicker";
import { Dropdown } from "./common/DropDown/Dropdown";
import ImagePicker from "./common/ImagePicker/ImagePicker";
import Query from "./common/Query/Query";
import RadioGroupButtons from "./common/RadioGroup/RadioGroupButtons";
import TagListingComponent from "./common/TagListing/TagListingComponent";
import TextArea from "./common/TextArea/TextArea";
import TextBox from "./common/TextBox/TextBox";
import Toggle from "./common/Toggle/Toggle";

const DynamicSectionComponent = ({
  fields,
  state,
  setState,
  errors,
  register,
  clearErrors,
  isEdit,
  contentType,
}: any) => {
  const handleChange = (event) => {
    clearErrors(event.target.name);
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const handleToggleChange = (event) => {
    //For Boolean type components
    setState({
      ...state,
      [event.target.name]: !state[event.target.name],
    });
  };
  const handleOnBlur = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const updateField = (updatedPartialObj, name) => {
    //For ImagePicker Component
    clearErrors(name);
    setState({
      ...state,
      [name]: {
        ...state[name],
        original_image: updatedPartialObj?.original_image,
        published_images: updatedPartialObj?.published_images,
      },
    });
  };

  const updateState = (name, value) => {
    //For TagListing Component
    clearErrors(name);
    setState({
      ...state,
      [name]: value,
    });
  };
  const optionCallBack = (field) => {
    //For Dynamic Dropdown
    if (state?.[field?.dependent_name]) {
      return field?.options?.filter((item) =>
        state?.[field?.dependent_name]?.map((x) => x.id).includes(item.parentId),
      );
    } else {
      return field?.options;
    }
  };

  function builder(field: any) {
    switch (field.type) {
      case "text":
        switch (field.variant) {
          case "multiline":
            return (
              <TextArea
                field={field}
                errors={errors}
                register={register}
                clearErrors={clearErrors}
                data={state[field?.name]}
                handleChange={handleChange}
                handleOnBlur={handleOnBlur}
              />
            );
          case "number":
            return (
              <TextBox
                type='number'
                field={field}
                errors={errors}
                register={register}
                clearErrors={clearErrors}
                data={state[field?.name]}
                handleChange={handleChange}
                handleOnBlur={handleOnBlur}></TextBox>
            );
          default:
            return (
              <TextBox
                type='text'
                field={field}
                errors={errors}
                register={register}
                clearErrors={clearErrors}
                data={state[field?.name]}
                handleChange={handleChange}
                handleOnBlur={handleOnBlur}></TextBox>
            );
        }
      case "image":
        return (
          <ImagePicker
            field={field}
            errors={errors}
            register={register}
            state={state}
            updateField={updateField}></ImagePicker>
        );
      case "content":
        return (
          <ContentTypePicker
            field={field}
            errors={errors}
            register={register}
            state={state}
            updateField={() => {}}
          />
        );
      case "query":
        return (
          <Query
            field={field}
            state={state}
            errors={errors}
            setState={setState}
            register={register}
            isEdit={isEdit}
            clearErrors={clearErrors}
          />
        );
      case "date":
        return (
          <DatePickerComponent
            disablePast={field?.disable_past || true}
            state={state}
            setState={setState}
            field={field}
            register={register}
            clearErrors={clearErrors}
            error={errors[field.name]}
          />
        );
      case "radio":
        return (
          <RadioGroupButtons
            value={field?.options}
            state={state}
            field={field}
            row={true}
            handleChange={handleChange}
            error={errors[field.name]}
            register={register}
          />
        );
      case "dropdown":
        return field?.variant === "searchdropdown" ? (
          <AutoCompleteDropDown
            options={field?.dependent_name ? optionCallBack(field) : field?.options}
            setState={setState}
            state={state}
            field={field}
            clearErrors={clearErrors}
            optionFormat='label'
            error={errors[field.name]}
            register={register}
          />
        ) : (
          <Dropdown
            options={field?.options}
            placeholder={field?.placeholder}
            setState={setState}
            state={state}
            error={errors[field.name]}
            register={register}
            field={field}
            clearErrors={clearErrors}
          />
        );
      // case "checkbox":
      //   return (
      //     <CheckBoxComponent
      //       field={field}
      //       error={errors[field.name]}
      //       register={register}
      //       handleChange={handleToggleChange}
      //       checked={state[field?.name]}
      //     />
      //   );
      case "boolean":
        switch (field.variant) {
          case "toggle":
            return (
              <Toggle
                field={field}
                error={errors[field.name]}
                register={register}
                disabled={false}
                color={"#000000"}
                onChange={handleToggleChange}
                checked={state[field?.name]}
                name={field?.name}
              />
            );
          case "checkbox":
            return (
              <CheckBoxComponent
                field={field}
                error={errors[field.name]}
                register={register}
                handleChange={handleToggleChange}
                checked={state[field?.name]}
              />
            );
          default:
            return null;
        }
      case "tag":
        return (
          <TagListingComponent
            field={field}
            isEdit={isEdit}
            selectedTag={state[field?.name]}
            subCategory={contentType}
            updateState={updateState}
            register={register}
            error={errors[field.name]}
          />
        );
      case "datetime":
        return (
          <DateAndTimePicker
            disablePast={field?.disable_past || true}
            field={field}
            error={errors[field.name]}
            register={register}
            state={state}
            updateState={updateState}
            clearErrors={clearErrors}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Container>
      <Grid container spacing={5} rowSpacing={2}>
        {fields?.map((field) => (
          <>
            {field?.type === "tag" ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className='textFiled'
                id={`${field?.name}_id`}>
                {builder(field)}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
                  <TitleSubTitle
                    titleVariant='h6medium'
                    subTitleVariant='p4regular'
                    title={
                      field?.validations[0]?.type === "required" ? `${field?.title}*` : field?.title
                    }
                    subTitle={field?.description}
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled'>
                  {builder(field)}
                </Grid>
              </>
            )}
          </>
        ))}
      </Grid>
    </Container>
  );
};

export default DynamicSectionComponent;
