import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";

export interface XDatePickerProps {
  isDisabled?: boolean;
  disablePast?: boolean;
  state?: any;
  setState?: any;
  field?: any;
  isDate?: boolean;
}

const XDateTimePicker = ({
  isDisabled,
  disablePast,
  state,
  field,
  setState,
  isDate,
}: XDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setState({ ...state, [field?.name]: new Date(date).toISOString() });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={selectedDate}
        onChange={handleDateChange}
        disabled={isDisabled}
        disablePast={disablePast}
        inputFormat='MM/DD/YYYY HH:mm'
        renderInput={(params) => (
          <TextField
            variant='filled'
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
            sx={{
              ".Platform-x-InputBase-root.Mui-error fieldset": {
                borderColor: "#ced3d9 !important",
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
export default XDateTimePicker;
