import { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getProductDetailsApiCall, getProductDetailsRewardpoints } from "../helperEcommerce";

import ToastService from "../../../components/ToastContainer/ToastService";
import { ecomProductImpressionEvent, getLocationStorage, getUserId } from "../../../utils/helper";
import ProductListing from "../ProductListing/ProductListing";
import "./ProductDetail.css";
import { useCustomStyle } from "./ProductDetail.style";
import ProductDisplay from "./SharedComponents/ProductDisplay";
import TabDocument from "./SharedComponents/TabDocument";

const ProductDetail = ({
  productId = "",
  secondaryArgs = {},
  cartCountUpdate = () => {},
  showAdditionalDetails = true,
}: any) => {
  const { t, i18n } = useTranslation();
  const classes = useCustomStyle();

  const [loading, setLoading] = useState(false);
  const [rewardPoints, set_rewardPoints] = useState("");
  const [variantsDetails, set_VariantsDetails] = useState<any>([]);
  const [productFullDetails, setProductFullDetails] = useState<any>({});

  const getPoitDetails = async (fetchEcomProductDetails: any) => {
    const { ecomx_sale_price = "" } = fetchEcomProductDetails;
    const amt = ecomx_sale_price ? "" + Math.round(ecomx_sale_price) : "";
    const response = await getProductDetailsRewardpoints(amt, secondaryArgs);
    const { data = {} } = response;
    const { result = "" } = data;
    if (result === 0) {
      set_rewardPoints("");
    } else {
      set_rewardPoints(result);
    }
  };

  /**
   * id based get product details get
   */
  const getProductDetails = async () => {
    setLoading(true);
    const res = await getProductDetailsApiCall(productId, secondaryArgs, variantsDetails);
    setLoading(false);
    const { data: { data: { publish_fetchEcomProductDetails = {} } = {} } = {}, status = 0 } = res;
    if (status === 200) {
      getPoitDetails(publish_fetchEcomProductDetails); //get reward point api
      setProductFullDetails(publish_fetchEcomProductDetails);
      const storedUserId = getUserId();
      const geolocationDataString = getLocationStorage();
      let geolocationData;
      if (geolocationDataString !== null) {
        geolocationData = JSON.parse(geolocationDataString);
      }
      const productViewData = {
        action: "w_product_view", // or "a_product_view" based on context
        content_type: "ecom", // Mapping given
        container_type: "container_value", // From where this action was triggered
        tags: "iphone11 | Case | mobilecase", // Example product categories
        product_Id: productId, // Adjust based on your data
        product_name: publish_fetchEcomProductDetails?.ecomx_name, // Product name
        product_price: publish_fetchEcomProductDetails?.ecomx_sale_price, // Product price
        product_category: publish_fetchEcomProductDetails?.product_category, // Product category
        page_name: publish_fetchEcomProductDetails?.ecomx_slug, // Page name (slug)
        page_url: window.location.href, // Product destination URL
        language: "en", // Or dynamically based on site language
        timestamp: new Date().toISOString(), // Timestamp of event
        site_name: window.location.host, // Your site's name
        userId: storedUserId, // keycloak ID or anonymous
        country: geolocationData?.country_name || "India", // Country
        city: geolocationData?.city, // City
        ip: geolocationData?.ip, // IP address (if available)
      };

      ecomProductImpressionEvent(productViewData);
    } else {
      ToastService.failToast(t("errorRequest"));
      setProductFullDetails({});
    }
  };

  /**
   * size variant handle
   * @param item
   */
  const sizeVariantsHandle = (item: any) => {
    const newArray = [...variantsDetails, item];
    const ids = newArray.map(({ key }) => key);
    const filtered = newArray.filter(({ key }: any, index) => !ids.includes(key, index + 1));
    set_VariantsDetails(filtered);
  };

  useEffect(() => {
    getProductDetails();
  }, [productId, variantsDetails]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
      if (globalThis?.rpiWebClient) {
        // const ls = localStorage.getItem("userId");
        // console.log("ls", ls);
        // globalThis.rpiWebClient.pushWebEvent("ecom", 1, "new-term", null, [
        //   { Name: "SVID", Value: "product-detail" },
        //   { Name: "FSID", Value: "FSID-FSID" },
        //   { Name: "EMID", Value: "EMID-EMID" },
        // ]);
        //console.log("globalThis.rpiWebClient", globalThis.rpiWebClient);
      }
    }
  }, []);

  return (
    <Box className={`${classes.productDetailWrapper} productDetailpage`}>
      <ProductDisplay
        rewardPoints={rewardPoints}
        secondaryArgs={secondaryArgs}
        loading={loading}
        productFullDetails={productFullDetails}
        productId={productId}
        sizeVariantsHandle={sizeVariantsHandle}
        t={t}
        cartCountUpdate={cartCountUpdate}
      />
      {showAdditionalDetails && (
        <>
          <Grid xs={12} className='prelem-py'>
            <TabDocument productFullDetails={productFullDetails} />
          </Grid>
          <Grid xs={12} className='productListingWrapper'>
            <ProductListing
              cartCountUpdate={cartCountUpdate}
              secondaryArgs={secondaryArgs}
              fromPage='productList'
            />
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ProductDetail;
