import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

const useDamContent = makeStyles(() => {
  const theme = useTheme();
  return {
    parentGrid: {
      background: theme?.palette?.customColor?.BGLIGHT,
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
      [theme.breakpoints.up("em")]: {
        display: "none",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "14px 32px 10px 15px",
        top: "50px !important",
      },
    },
    marginLeft: {
      marginLeft: "6px",
    },
    parent: {
      [theme.breakpoints.down("sm")]: {
        margin: "25px 13px 10px 13px !important",
        justifyContent: "space-between",
        width: "auto !important",
      },
      justifyContent: "space-between",
      margin: "20px 20px 10px 22px",
      width: "auto !important",
    },
    filterbox: {
      display: "flex",
      border: "1px solid #14142B",
      justifyContent: "space-evenly",
      borderRadius: "5px",
      padding: "13px 14px",
      height: "44px",
      cursor: "pointer",
    },
    typographyshow: {
      margin: "15px 15px 15px 21px !important",
    },
    typoText: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      display: "flex",
      padding: "0 10px 0px 10px",
      alignItems: "center",
      textTransform: "capitalize",
    },
    typoTextn: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      display: "flex",
      padding: "0 10px 0px 10px",
      alignItems: "center",
    },
    folderLabel: {
      display: "flex",
      alignItems: "center",
      marginLeft: "10px !important",
    },
    folderBox: {
      display: "flex",
      justifyContent: "space-between",
      height: "40px",
      padding: "10px 0px",
      cursor: "pointer",
      "&.nextlevel + .firstLevel": {
        marginLeft: "28px",
        "& p": {
          fontWeight: "400",
        },
      },
    },
    contentcontrol: {
      width: "94.5%",
      maxWidth: "324px",
      wordBreak: "break-all",
    },
    filtercontainer: {
      width: "45px",
      height: "45px",
      borderRadius: "5px",
      border: `1px solid ${theme.palette.customColor.PRIMARY}`,
    },
    filterimage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "13px",
    },
    datepickerbox: {
      marginTop: "10px",
      padding: "13px",
    },
    toptypo: {
      margin: "20px 20px",
    },
    topboxmargin: {
      marginTop: "5px",
    },
    dampadding: {
      padding: "20px !important",
      "& .leftsidebar-scroll": {
        "& .selectRoot": {
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(57vh)",
          width: "100%",
          paddingLeft: "15px",
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
          },
        },
      },
      "& .damcontentfolder": {
        padding: "30px 15px 0px",
      },
    },
    filtermenu: {
      padding: 2,
      display: "flex",
      gap: "15px",
    },
    filterborderbox: {
      height: "45px",
      width: "45px",
      border: "1px solid grey",
      borderRadius: "5px",
    },
    filtercontainerbox: {
      padding: "12px",
    },

    outerSelectBox: {
      height: "44px",
    },
    dropDownOptions: {
      WebkitLineClamp: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "160px",
      margin: "5px 0 !important",
    },
    menuItemhover: {
      padding: "5px 20px",
      margin: "0px !important",
      "&:hover": {
        backgroundColor: theme.palette.customColor.BGWEAK,
      },
      "&:hover p, &.active p": {
        color: theme.palette.customColor.PRIMARY,
        fontWeight: 600,
      },
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      padding: "5px 20px",
      "&.Mui-selected": {
        backgroundColor: theme.palette.customColor.BGWEAK,
        "& p": {
          color: theme.palette.customColor.PRIMARY,
          fontWeight: 600,
        },
      },
    },
    authoricon: {
      minWidth: "33px !important",
    },
    menuitembox: {
      maxHeight: 200,
      overflowY: "auto",
      minWidth: "205px",
    },
    listitembox: {
      paddingLeft: "1px !important",
    },
    menudemo: {
      [theme.breakpoints.down("xs")]: {
        minwidth: "120px !important",
      },
    },
    mobilefilter: {
      height: "46px",
      display: "none",
      padding: "13px 14px",
      borderRadius: "5px",
      border: "1px solid #E6EAED",
      [theme.breakpoints.down("sm")]: {
        height: "46px",
        display: "flex",
        padding: "13px 14px",
        borderRadius: "5px",
        border: "1px solid #E6EAED",
      },
    },
  };
});

export default useDamContent;
